function Meetrisk({ width = 140, height = 50 }) {
  return (
    <img
      alt='Meetrisk logo'
      src={require('./Meetrisk.png')}
      style={{ width, height }}
    />
  );
}

export default Meetrisk;
