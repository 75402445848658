function Jetnet({ width = 140, height = 50 }) {
  return (
    <img
      alt='Jetnet logo'
      src={require('./Jetnet.jpg')}
      style={{ width, height }}
    />
  );
}

export default Jetnet;
