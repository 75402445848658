import { useAppSelector } from '.';
import useIntegrations from '../components/FormIntegrations/useIntegrations';
import { INTEGRATIONS } from '../components/FormIntegrations';
import { useParams } from 'react-router-dom';
import { useCallback, useEffect, useMemo, useState } from 'react';
import useFeatheryRedux from '../redux';
import {
  QuikFieldRow,
  QuikTableColumn
} from '../components/Modals/FieldSelectorWithModal/QuikFieldSelector';
import { SfFieldMappingData } from '../components/FormIntegrations/QuikSettingsSidebar/components/UploadFieldMappingDialog';

export interface QuikFieldData {
  id: string;
  role: string;
  repeatable: boolean;
}

interface QuikSelectorTab {
  key: string;
  label: string;
  columns: QuikTableColumn[];
  rows: QuikFieldRow[];
  onRowSelect?: (row: QuikFieldRow) => void;
  selectedRowId?: string;
}

export const QUIK_FIELD_UNSUPPORTED_TYPES = new Set([
  'button_group',
  'pin_input',
  'hex_color',
  'password',
  'payment_method',
  'slider',
  'url',
  'qr_scanner',
  'rating'
]);

const getRole = (quikField: any) => {
  if (quikField.RoleShortName) {
    return quikField.RoleShortName;
  } else if (quikField.FieldShortName) {
    return quikField.FieldShortName;
  }
  return 'None';
};

const mapFields = (fields: Map<string, QuikFieldData>) => {
  return Array.from(fields.values())
    .sort((a, b) => {
      const aKey = a.id.replace(/^\d+/, '');
      const bKey = b.id.replace(/^\d+/, '');
      return aKey.localeCompare(bKey);
    })
    .map((field) => ({
      value: field.id,
      label: field.id,
      role: field.role,
      repeatable: field.repeatable
    }));
};

export default function useQuikFieldConfig(servarMeta?: any) {
  const { formId } = useParams<{ formId: string }>();
  const { fetchQuikFields } = useFeatheryRedux();

  const isEnterprise = useAppSelector((state) => {
    const org = state.accounts.organization;
    return !!(org && org.tier >= 4);
  });

  const quikIntegration = useIntegrations({
    type: INTEGRATIONS.QUIK,
    panelId: formId
  });

  const isQuikConnected = useAppSelector((state) => {
    const org = state.accounts.organization;
    return !!(
      quikIntegration &&
      org &&
      isEnterprise &&
      org.enterprise_features.quik
    );
  });

  const quikMeta = quikIntegration?.data.secret_metadata;
  const quikFields = useAppSelector((state) => state.integrations.quikFields);

  useEffect(() => {
    if (!isQuikConnected) return;

    quikMeta.form_ids.forEach((quikFormId: string) => {
      quikFormId = quikFormId.split(':')[0];
      if (!quikFields[quikFormId])
        fetchQuikFields({ panelId: formId, quikFormId });
    });
  }, [isQuikConnected]);

  const {
    quikSelectFields,
    quikPhoneNumFields,
    quikTextFields,
    quikSignatureEntities,
    quikAllFields
  } = useMemo(() => {
    if (!isQuikConnected)
      return {
        quikSelectFields: [],
        quikPhoneNumFields: [],
        quikTextFields: [],
        quikSignatureEntities: [],
        quikAllFields: []
      };

    const selectFields = new Map<string, QuikFieldData>();
    const phoneNumFields = new Map<string, QuikFieldData>();
    const textFields = new Map<string, QuikFieldData>();
    const signatureEntities = new Map<string, QuikFieldData>();
    const allFields = new Map<string, QuikFieldData>();

    const updateField = (quikField: any, repeatable: boolean) => {
      const quikFieldName = quikField.FullFieldName;
      // store field id without leading number if repeatable for use in repeatable containers
      // ex. 2ben.FullName -> ben.FullName
      const id = repeatable ? quikFieldName.substring(1) : quikFieldName;
      const role = getRole(quikField);

      const fieldData = {
        id,
        role,
        repeatable
      };

      if (quikField.RoleName === 'User') {
        // Include user-defined fields in both select and text types
        selectFields.set(id, fieldData);
        textFields.set(id, fieldData);
      } else if (quikField.FieldValue != null) {
        // Field has specific options
        selectFields.set(id, fieldData);
      } else if (id.endsWith('Sign')) {
        const entity = quikFieldName.split('.')[0];
        fieldData.id = entity;
        signatureEntities.set(entity, fieldData);
      } else {
        // Generic text fields
        textFields.set(id, fieldData);

        // categorize phone number fields
        if (id.endsWith('Phone') || id.endsWith('Mobile')) {
          phoneNumFields.set(id, fieldData);
        }
      }

      // Maintain a list of all Quik Fields
      allFields.set(id, fieldData);
    };

    quikMeta.form_ids?.forEach((quikFormId: string) => {
      const normalizedFormId = quikFormId.split(':')[0];
      quikFields[normalizedFormId]?.forEach((quikField) => {
        updateField(quikField, false);
        const repeatable = quikField.FullFieldName.startsWith('2');
        if (repeatable) updateField(quikField, true);
      });
    });

    return {
      quikSelectFields: mapFields(selectFields),
      quikPhoneNumFields: mapFields(phoneNumFields),
      quikTextFields: mapFields(textFields),
      quikSignatureEntities: mapFields(signatureEntities),
      quikAllFields: mapFields(allFields)
    };
  }, [quikFields, quikMeta, isQuikConnected]);

  const quikFieldsByType = [
    { label: 'Text Fields', value: 'text_field', options: quikTextFields },
    {
      label: 'Single Select Fields',
      value: 'select',
      options: quikSelectFields
    },
    {
      label: 'Phone Number Fields',
      value: 'phone_number',
      options: quikPhoneNumFields
    },
    {
      label: 'Signature Fields',
      value: 'signature',
      options: quikSignatureEntities
    }
  ];

  const getQuikFieldIDOptions = (elementType: string) => {
    switch (elementType) {
      case 'select':
        return quikSelectFields;
      case 'text_field':
        return quikTextFields;
      case 'phone_number':
        return quikPhoneNumFields;
      default:
        // display all quik field IDs for any other Feathery field types
        return quikAllFields;
    }
  };

  const isQuikSelectField = (quikFieldId: string) => {
    return quikSelectFields.some((field) => quikFieldId === field.value);
  };

  const getQuikFieldSelectorTabData = (
    onSelect: (id: string) => void,
    elementType: string,
    isRepeatable: boolean
  ): QuikSelectorTab[] => {
    let filteredQuikFieldsByType = quikFieldsByType;

    const typeMatch = quikFieldsByType.find(
      ({ value }) => value === elementType
    );

    if (typeMatch) {
      filteredQuikFieldsByType = [typeMatch];
    }

    return filteredQuikFieldsByType
      .filter(({ options }) => options && options.length > 0)
      .map(({ label, options = [], value }) => ({
        key: value,
        label,
        columns: [
          { key: 'field', name: 'Name' },
          { key: 'role', name: 'Role' }
        ],
        rows: options
          .filter((field) => field.repeatable === isRepeatable)
          .map((field) => ({
            id: field.label,
            field: field.value,
            role: field.role
          })),
        onRowSelect: (row) => onSelect(row.id),
        selectedRowId: servarMeta.quik_field_id
      }));
  };

  const [sfFieldMapping, setSfFieldMapping] = useState<SfFieldMappingData>({});

  const formBuilderSteps = useAppSelector(
    (state) => state.formBuilder.workingSteps
  );

  const formFields = useMemo(() => {
    return Object.values(formBuilderSteps).flatMap((step: any) =>
      (step as any).servar_fields.map((field: any) => field.servar)
    );
  }, [formBuilderSteps]);

  const getFeatheryFieldByQuikId = useCallback(
    (fieldId: string) =>
      formFields.find(
        (field: any) => field.metadata.quik_field_id === fieldId
      ) || null,
    [formFields]
  );

  useEffect(() => {
    const metadata = quikIntegration?.data.secret_metadata;
    setSfFieldMapping(metadata?.sf_field_mapping ?? {});
  }, [quikIntegration]);

  const updateSfFieldValuesConfig = useCallback(
    (sfConfigState: any) => {
      const relevantFieldMapping = sfFieldMapping[sfConfigState.object] ?? [];
      relevantFieldMapping.forEach((fieldMap: any) => {
        const field = getFeatheryFieldByQuikId(fieldMap.quikField);
        if (field) {
          sfConfigState['field_values'][fieldMap.sfField] = `{{${field.key}}}`;
        }
      });
    },
    [quikIntegration, sfFieldMapping, getFeatheryFieldByQuikId]
  );

  return {
    isQuikConnected,
    getQuikFieldIDOptions,
    isQuikSelectField,
    getQuikFieldSelectorTabData,
    updateSfFieldValuesConfig
  };
}
