import {
  CollapsibleSection,
  DropdownField,
  DropdownMultiField,
  InlineTooltip,
  NumberInput,
  Switch,
  TextField
} from '../../../components/Core';
import { defaultQuestionGroup } from '../utils';
import Label from '../../../components/Dialog/Label';
import styles from '../styles.module.scss';
import '../../../style/dialog-form.css';

export default function ExtractionProperties({
  formData,
  handleChange,
  accounts,
  fileOptions,
  hiddenFieldOptions,
  usedFields,
  isCreate = false
}: any) {
  return (
    <>
      <Label>Name</Label>
      <TextField
        value={formData.key}
        placeholder='My Extraction'
        onComplete={(newKey: string) => handleChange({ key: newKey })}
        className={styles.modalInput}
        required
      />
      {!isCreate && (
        <>
          <div className={styles.labelContainer}>
            <Label>Reviewers</Label>
            <InlineTooltip text='Reviewers will be notified via email to review & approve new extraction runs.' />
          </div>
          <DropdownMultiField
            selected={formData.reviewers}
            options={accounts.map((account: any) => ({
              label: account.email,
              value: account.id
            }))}
            onChange={(items: { value: string }[]) =>
              handleChange({ reviewers: items.map((item) => item.value) })
            }
            className={styles.modalInput}
          />
        </>
      )}

      <div className={styles.labelContainer}>
        <Label>File Format</Label>
        <InlineTooltip text='The type of files being processed by this extraction' />
      </div>
      <DropdownField
        selected={formData.file_type}
        options={[
          { value: 'image', display: 'Documents or images' },
          { value: 'csv', display: 'Spreadsheets or CSVs' }
        ]}
        onChange={(event: any) =>
          handleChange({
            file_type: event.target.value,
            question_groups: [defaultQuestionGroup(event.target.value)],
            page_filter:
              event.target.value !== 'image' ? null : formData.page_filter,
            file_filter_query:
              event.target.value !== 'image' ? '' : formData.file_filter_query, // Clear filter if not image extraction
            extraction_type:
              event.target.value !== 'image' ? '' : formData.extraction_type // Clear template if not image extraction
          })
        }
        className={styles.modalInput}
        required
      />
      <div className={styles.labelContainer}>
        <Label>File Upload Source</Label>
        <InlineTooltip text='The extraction will run on files stored in these fields' />
      </div>
      <DropdownMultiField
        selected={formData.file_sources}
        options={[...fileOptions, ...hiddenFieldOptions].filter(
          (option) =>
            formData.file_sources.includes(option.value) ||
            !usedFields.includes(option.value)
        )}
        onChange={(items: { value: string }[]) =>
          handleChange({ file_sources: items.map((item) => item.value) })
        }
        className={styles.modalInput}
        required
      />
      {!isCreate && (
        <CollapsibleSection
          title='Filter & Merge Options'
          expanded={true}
          separator={false}
          customClasses={{
            content: styles.mergeFilterSection,
            title: styles.mergeFilterTitle,
            header: styles.mergeFilterHeader,
            expandIndicator: styles.mergeFilterIndicator
          }}
        >
          {formData.file_type === 'image' && (
            <>
              <div
                className={styles.labelContainer}
                style={{ marginTop: '10px' }}
              >
                <Label>Filter Files</Label>
                <InlineTooltip text='Describe the uploaded files that should be processed by this extraction. Uploaded files that do not match the description will be ignored.' />
              </div>
              <TextField
                value={formData.file_filter_query}
                placeholder='Bank statements'
                onComplete={(newFilter: string) =>
                  handleChange({ file_filter_query: newFilter })
                }
                className={styles.modalInput}
              />
              <div className={styles.labelContainer}>
                <Label>Limit Page Count</Label>
                <InlineTooltip text="Only process the first N pages of each file. If `Merge Files` is enabled, this runs on each file before they're merged." />
              </div>
              <NumberInput
                classNames={{ root: styles.modalInput }}
                min={1}
                placeholder={3}
                value={formData.page_filter}
                onComplete={({ value }: any) => {
                  handleChange({ page_filter: value });
                }}
              />
            </>
          )}
          <div className={styles.labelContainer}>
            <Label>Merge Files</Label>
            <InlineTooltip text='The extraction will run once on the combined files rather than separately on each.' />
          </div>
          <Switch
            id='extraction-merge-uploaded'
            checked={formData.merge_files || false}
            onCheckedChange={(checked) => {
              handleChange({ merge_files: checked });
            }}
            className={styles.switch}
          />
        </CollapsibleSection>
      )}
    </>
  );
}
