function Guidewire({ width = 70, height = 70 }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 1440 1443'
      width={width}
      height={height}
    >
      <path
        fill='#00739d'
        d='m1439.9 289.4v-288.8h-1150.9-288.2v288.8 864.2 288.8h288.2 574.5v-288.8h-574.5v-864.2z'
      />
      <path
        fill='#00739d'
        d='m1439.9 578.1v286.8 577.5h-288.2v-577.5h-576.4v-286.8z'
      />
    </svg>
  );
}

export default Guidewire;
