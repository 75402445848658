import {
  defaultQuestionGroup,
  getDefaultEntity,
  questionGroupTemplates
} from '../utils';
import produce from 'immer';
import QuestionGroup from './QuestionGroup';
import '../../../style/dialog-form.css';
import useFeatheryRedux from '../../../redux';
import { useSyncedRefState } from '../../../hooks/useSyncedRefState';
import '../../../style/dialog-form.css';
import { Button } from '../../../components/Core/Button/button';
import { DropdownField, InlineTooltip } from '../../../components/Core';
import Label from '../../../components/Dialog/Label';
import styles from '../styles.module.scss';
import '../../../style/dialog-form.css';
import { ExtractionFormData } from '../ExtractionCreateModal/useExtractionForm';
import { FieldProperties } from '../ExtractionCreateModal/types';

interface ExtractionQueriesProps {
  extractionId: null | string;
  formData: ExtractionFormData;
  handleChange: (data: Partial<ExtractionFormData>) => void;
  fieldProperties: Record<string, FieldProperties>;
  hiddenFieldProperties: Record<string, Partial<FieldProperties>>;
  fieldNames: Record<string, string>;
}
export default function ExtractionQueries({
  extractionId = null,
  formData,
  handleChange,
  fieldProperties,
  hiddenFieldProperties,
  fieldNames
}: ExtractionQueriesProps) {
  const {
    toasts: { addErrorToast }
  } = useFeatheryRedux();
  const getQuestionGroups = useSyncedRefState<
    ExtractionFormData['question_groups']
  >(formData.question_groups);
  const getVariants = useSyncedRefState<ExtractionFormData['variants']>(
    formData.variants
  );

  const removeQuestionGroup = (index: number) => {
    const groups = getQuestionGroups();
    const group_id = groups[index].id;
    const newGroups = produce(groups, (draft: any[]) => {
      if (draft.length === 1) {
        draft[0] = defaultQuestionGroup(formData.file_type);
        return;
      } else {
        draft.splice(index, 1);
      }
    });

    // remove any group_variants attached to this group
    const newVariants = produce(getVariants(), (draft: any[]) => {
      for (let i = 0; i < draft.length; i++) {
        if (draft[i].group_variants) {
          draft[i].group_variants = draft[i].group_variants.filter(
            (groupVariant: any) => groupVariant.question_group_id !== group_id
          );
        }
      }
    });

    handleChange({
      question_groups: newGroups,
      variants: newVariants
    });

    let newIndex = index;
    if (!(newGroups as any)[newIndex]) {
      newIndex -= 1;
      if (!(newGroups as any)[newIndex]) {
        newIndex = 0;
      }
    }
  };

  const addQuestionGroup = (copy?: any) => {
    const newGroup = defaultQuestionGroup(formData.file_type, copy);
    const groups = getQuestionGroups();
    let newGroups;
    if (copy) {
      // Insert copy after the original
      const copiedIndex = groups.findIndex((group) => group.id === copy.id);
      newGroups = [
        ...groups.slice(0, copiedIndex + 1),
        newGroup,
        ...groups.slice(copiedIndex + 1)
      ];
    } else {
      // Add to end
      newGroups = [...groups, newGroup];
    }
    handleChange({
      question_groups: newGroups
    });
  };

  const updateQuestionGroup = (
    index: number,
    key: string,
    val: any,
    entityIndex?: number,
    operation?: 'add' | 'remove'
  ) => {
    const newData: Record<string, any> = {};

    const groups = getQuestionGroups();
    const groupId = groups[index].id;

    const newGroups = produce(groups, (draft: any[]) => {
      draft[index][key] = val;

      if (key === 'question_type') {
        draft[index].field_ids = [];
        draft[index].entities = [getDefaultEntity()];
        draft[index].criteria = '';
        draft[index].toggle_page_question = false;
        draft[index].run_email = false;
      }

      if (key === 'entities') {
        draft[index].field_ids = draft[index][key].map(
          (e: any) => e.field_id ?? null
        );
      }
    });
    newData.question_groups = newGroups;

    const seenFieldIds = new Set<string>();
    const hasDuplicate = Object.values(newGroups).some((group) => {
      return group.field_ids.some((fieldId: string | null) => {
        if (!fieldId) return false;
        if (seenFieldIds.has(fieldId)) return true;
        seenFieldIds.add(fieldId);
        return false;
      });
    });

    if (hasDuplicate) {
      addErrorToast({ title: 'Field is already used!' });
      return;
    }

    if (entityIndex != null && operation) {
      // Update variants to match the field changes
      const newVariants = produce(getVariants(), (draft: any[]) => {
        draft.forEach((variant) => {
          const groupVariantIndex = variant.group_variants?.findIndex(
            (group: any) => group.question_group_id === groupId
          );

          if (groupVariantIndex !== -1 && groupVariantIndex != null) {
            const groupVariant = variant.group_variants[groupVariantIndex];

            if (key === 'question_type') {
              // Reset field_ids
              groupVariant.field_ids = [];
            } else if (key === 'entities') {
              if (operation === 'add' && entityIndex !== undefined) {
                // add null at index
                groupVariant.field_ids.splice(entityIndex, 0, null);
              } else if (operation === 'remove' && entityIndex !== undefined) {
                // remove field at index
                groupVariant.field_ids.splice(entityIndex, 1);
              }
            }

            // remove groupVariant if it's only nulls
            if (groupVariant.field_ids.filter(Boolean).length === 0)
              variant.group_variants.splice(groupVariantIndex, 1);
          }
        });
      });

      newData.variants = newVariants;
    }

    handleChange(newData);
  };
  const moveQuestionGroupUp = (index: number) => {
    const newGroups = produce(getQuestionGroups(), (draft: any) => {
      if (index > 0) {
        [draft[index - 1], draft[index]] = [draft[index], draft[index - 1]];
      }
    });
    handleChange({ question_groups: newGroups });
  };

  const moveQuestionGroupDown = (index: number) => {
    const newGroups = produce(getQuestionGroups(), (draft: any) => {
      if (index < draft.length - 1) {
        [draft[index], draft[index + 1]] = [draft[index + 1], draft[index]];
      }
    });
    handleChange({ question_groups: newGroups });
  };

  return (
    <>
      {formData.file_type === 'image' && (
        <>
          <div className={styles.labelContainer}>
            <Label>Template</Label>
            <InlineTooltip text='Leverage query templates and optimized processing based on the type of document being processed.' />
          </div>
          <DropdownField
            selected={formData.extraction_type}
            options={[
              { value: '', display: 'Custom' },
              { value: 'holdings', display: 'Security Holdings Statement' },
              {
                value: 'bank_transactions',
                display: 'Bank Transactions Statement'
              },
              { value: 'acord', display: 'ACORD Forms', disabled: true },
              {
                value: 'supplemental_insurance_application',
                display: 'Supplemental Insurance Application',
                disabled: true
              },
              {
                value: 'patient_referral',
                display: 'Patient Referral Form',
                disabled: true
              },
              {
                value: 'quarterly_fund_investment_report',
                display: 'Quarterly Fund Investment Report',
                disabled: true
              },
              {
                value: 'financial_investment_statements',
                display: 'Financial Investment Statements',
                disabled: true
              },
              {
                value: 'capital_account_investment_report',
                display: 'Capital Account Investment Report',
                disabled: true
              }
            ]}
            onChange={(event: any) => {
              const newData: Record<string, any> = {};
              const val = event.target.value;
              if (val in questionGroupTemplates) {
                // @ts-ignore
                newData.question_groups = questionGroupTemplates[val]();
              } else {
                const oldVal = formData.extraction_type ?? '';
                if (oldVal in questionGroupTemplates) {
                  newData.question_groups = [
                    defaultQuestionGroup(formData.file_type)
                  ];
                }
              }

              newData.extraction_type = val;
              if (val) newData.file_type = 'image';
              handleChange(newData);
            }}
            className={styles.modalInput}
          />
        </>
      )}
      <div className={styles.labelContainer}>
        <Label>Queries</Label>
        <InlineTooltip text='The query will be used by AI to find answer(s) from each document, and the answer(s) will be saved into the field(s)' />
      </div>
      <div className='space-y-3'>
        {formData.question_groups.map((group: any, index: number) => (
          <QuestionGroup
            key={group.id}
            extractionId={extractionId}
            index={index}
            group={group}
            fieldProperties={fieldProperties}
            hiddenFieldProperties={hiddenFieldProperties}
            isLast={index === formData.question_groups.length - 1}
            file_type={formData.file_type}
            fieldNames={fieldNames}
            onRemove={() => removeQuestionGroup(index)}
            onMoveUp={() => moveQuestionGroupUp(index)}
            onMoveDown={() => moveQuestionGroupDown(index)}
            onUpdate={(...props) => updateQuestionGroup(index, ...props)}
            onCopy={() => addQuestionGroup(group)}
          />
        ))}
      </div>
      <Button onClick={() => addQuestionGroup()} className='my-6 mb-[64px]'>
        Add Query
      </Button>
    </>
  );
}
