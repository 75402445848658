import type { FieldProperties } from '../ExtractionCreateModal/types';
import Label from '../../../components/Dialog/Label';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { ENTITY_DATA, GROUP_TYPE } from '../utils';
import Entity from './Entity';
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger
} from '../../../components/Core/Collapsible';
import { forwardRef } from 'react';

type Label = {
  label: string;
  value: string;
};

type Props = {
  index: number;
  group: any;
  fieldProperties: Record<string, FieldProperties>;
  hiddenFieldProperties: Record<string, Partial<FieldProperties>>;
  file_type: string;
  onUpdate: (entityIndex: number, newFieldId: string | null) => void;
  variant_group?: null | {
    question_group_id: string;
    field_ids: string[];
  };
  fieldNames: Record<string, string>;
};

function VariantGroup(props: Props) {
  const {
    index,
    group,
    fieldProperties,
    hiddenFieldProperties,
    onUpdate,
    variant_group,
    fieldNames
  } = props;

  return (
    <Collapsible asChild>
      <div
        key={`question-group-${index + 1}`}
        className={classNames(
          styles.questionGroupSection,
          styles.card,
          styles.white
        )}
      >
        <CollapsibleTrigger asChild>
          <QuestionGroupHeader
            entities={group.entities}
            fieldNames={fieldNames}
            variant_fields={variant_group?.field_ids ?? []}
          />
        </CollapsibleTrigger>
        <CollapsibleContent asChild>
          <div className={styles.keyValuePair}>
            <div className={styles.entitiesContainer}>
              {group.entities?.map((entity: any, entityIndex: number) => (
                <Entity
                  key={`group-${index}-entity-${entityIndex + 1}`}
                  type={group.question_type}
                  entity={entity}
                  fieldProperties={fieldProperties}
                  hiddenFieldProperties={hiddenFieldProperties}
                  onUpdate={(key: string | object, value?: any) => {
                    if (key !== 'field_id') return;
                    onUpdate(entityIndex, value);
                  }}
                  onRemove={() => {}}
                  variant_id={variant_group?.field_ids[entityIndex] || ''}
                  {...ENTITY_DATA[group.question_type as GROUP_TYPE]}
                />
              ))}
            </div>
          </div>
        </CollapsibleContent>
      </div>
    </Collapsible>
  );
}

export default VariantGroup;

const QuestionGroupHeader = forwardRef(
  (
    { entities, fieldNames, variant_fields, index, ...props }: any,
    ref: any
  ) => {
    return (
      <div ref={ref} className={styles.header} {...props}>
        <div className={styles.labelContainer}>
          {entities.map((entity: any, index: number) => (
            <Label key={index} className={styles.label}>
              <p className='line-clamp-1'>{entity.name || `Unnamed Entity`}</p>
              <p className={styles.fieldName}>
                ({fieldNames[variant_fields[index]] ?? 'Unmapped'})
              </p>
            </Label>
          ))}
        </div>
      </div>
    );
  }
);
QuestionGroupHeader.displayName = 'QuestionGroupHeader';
