import { memo } from 'react';
import { Neutral, Positive } from '../../../components/Core/Button';
import useModalSubmissionLockout from '../../../utils/useModalSubmissionLockout';
import {
  PermissionConfigProps,
  usePermissionsConfig
} from '../../../hooks/usePermissionsConfig';
import RoleDropdown from './RoleDropdown';
import styles from '../styles.module.scss';

export const roleValueToLabel: Record<string, string> = {
  admin: 'Admin',
  editor: 'Editor',
  viewer: 'Viewer'
};

function PermissionsConfig({
  role = 'admin',
  permissionEditFormResults = true,
  permissionInviteCollaborators = true,
  permissionEditCollaboratorTemplate = true,
  permissionEditFormDesign = true,
  permissionEditLogic = true,
  permissionEditTheme = true,
  permissionEditLinkedProperties = true,
  permissionDeleteForms = true,
  permissionPublishForms = true,
  permissionFilterResults = null,
  showUserGroups = false,
  onSubmit,
  cta = 'Save',
  onBack
}: PermissionConfigProps & {
  onSubmit: (data: Record<string, any>) => void;
  cta: string;
  onBack: () => void;
}) {
  const { getSubmitFunction, ...permissionsConfigObjects } =
    usePermissionsConfig({
      role,
      permissionEditCollaboratorTemplate,
      permissionEditFormResults,
      permissionEditFormDesign,
      permissionEditLogic,
      permissionEditLinkedProperties,
      permissionEditTheme,
      permissionDeleteForms,
      permissionPublishForms,
      permissionFilterResults,
      permissionInviteCollaborators,
      showUserGroups
    });

  const { lockOutFlag, lockoutFunction } = useModalSubmissionLockout(
    getSubmitFunction(onSubmit)
  );

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        return lockoutFunction();
      }}
    >
      <RoleDropdown
        showUserGroups={Boolean(showUserGroups)}
        permissionsConfigObjects={permissionsConfigObjects}
        dropDownClass={styles.roleStyles}
        permissionsStyles={{ width: '400px' }}
      />
      <div className='dialog-form-action text-center'>
        {onBack && <Neutral onClick={onBack}>Back</Neutral>}
        <Positive lockoutOverride={lockOutFlag}>{cta}</Positive>
      </div>
    </form>
  );
}

export default memo(PermissionsConfig);
