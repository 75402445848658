function Verisk({ width = 80, height = 80 }) {
  return (
    <svg
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      viewBox='0 0 45.8 44.9'
      width={width}
      height={height}
      xmlSpace='preserve'
    >
      <style>
        {`
          .st0 { fill: #00358E; }
          .st1 { fill: #2A7DE1; }
        `}
      </style>
      <g>
        <path
          className='st0'
          d='M3.3,10.8C3.6,10.3,4.2,10,4.8,10h7.1c0.3,0,0.4,0.3,0.3,0.5l-1.9,4.3C10.2,14.9,10.1,15,10,15H1.8
            c-0.3,0-0.4-0.3-0.3-0.5C2,13.1,2.6,11.9,3.3,10.8z M9.6,5C9.3,5,9.1,4.5,9.4,4.3C13.2,1.6,17.9,0,23,0s9.7,1.6,13.5,4.4
            C36.8,4.6,36.6,5,36.3,5H9.6z M15.8,40.1c0.1-0.1,0.2-0.2,0.3-0.2L36.2,40c0.3,0,0.5,0.4,0.2,0.6c-3.8,2.7-8.5,4.3-13.5,4.3
            c-2.9,0-5.6-0.5-8.1-1.5c-0.2-0.1-0.3-0.3-0.2-0.5L15.8,40.1z M18.3,34.4l1.9-4.3c0.1-0.1,0.2-0.2,0.3-0.2L44,30
            c0.3,0,0.4,0.3,0.3,0.5c-0.5,1.3-1.1,2.5-1.8,3.7C42.2,34.7,41.6,35,41,35l-22.3-0.1C18.4,34.9,18.2,34.6,18.3,34.4z M45.7,20.3
            c0.1,0.7,0.1,1.4,0.1,2.2c0,0.7,0,1.5-0.1,2.2c0,0.2-0.2,0.3-0.4,0.3L23,24.9c-0.3,0-0.4-0.3-0.3-0.5l1.2-2.8l0,0
            c0.5-1,1.5-1.7,2.6-1.7h18.8C45.5,20,45.7,20.2,45.7,20.3z'
        />
        <path
          className='st1'
          d='M44.3,14.5c-0.5-1.3-1.1-2.5-1.8-3.7C42.2,10.3,41.6,10,41,10H19.7c-1.1,0-2,0.6-2.5,1.6l-7.1,16.1
            c0,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.1,0-0.2-0.1L7,21.5c-0.4-1-1.4-1.6-2.5-1.6h-4c-0.2,0-0.3,0.1-0.4,0.3C0,21,0,21.7,0,22.5
            c0,0.7,0,1.4,0.1,2.2c0,0.2,0.2,0.3,0.4,0.3h2.1c0.1,0,0.3,0.1,0.3,0.2c0.6,1.3,3.5,8,5.4,12.3c0.3,0.7,1,1.1,1.6,1.1
            c0.7,0,1.3-0.4,1.6-1.1l9.2-20.9c0.4-1,1.4-1.6,2.5-1.6H44C44.2,15,44.4,14.7,44.3,14.5z'
        />
      </g>
    </svg>
  );
}

export default Verisk;
