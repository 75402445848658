function Dynarisk({ width = 140, height = 50 }) {
  return (
    <img
      alt='Dynarisk logo'
      src={require('./Dynarisk.png')}
      style={{ width, height }}
    />
  );
}

export default Dynarisk;
