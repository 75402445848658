function SambaSafety({ width = 160, height = 160 }) {
  return (
    <svg
      width={width}
      height={height}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 652 652'
      enableBackground='new 0 0 652 652'
      xmlSpace='preserve'
    >
      <style type='text/css'>
        {`
	        .st0{fill:#414141;}
          .st1{fill:#1B75BA;}
          .st2{clip - path:url(#SVGID_4_);}
          .st3{clip - path:url(#SVGID_5_);}
        `}
      </style>
      <g>
        <path
          className='st0'
          d='M214,313.9c-1.2-5.4-4.5-6.9-11.4-6.9c-5,0-9.6,1.1-9.6,5.7c0,3.4,2.2,4.1,12.3,5.5
		c10.1,1.3,14.4,3.1,14.4,9.6c0,6.6-5.4,10.5-15.8,10.5c-10.5,0-16.8-3.4-16.8-11.4h4.3c0.8,5.5,4,7.5,12.4,7.5c6.6,0,11-1.3,11-6.3
		c0-3.3-2.4-4.8-12.7-5.8c-10.3-1-14-3.4-14-9.3c0-5.7,4.7-9.8,14.3-9.8c10.8,0,15.2,3.9,15.8,10.8H214z'
        />
        <path
          className='st0'
          d='M223.9,314.3c0.7-6.9,4.5-11.2,15.8-11.2c11.1,0,15.2,3.4,15.2,9.8v17.3c0,2.7,0.8,3.7,2.6,3.7
		c0.8,0,1.7-0.1,2.5-0.1v3.2c-1,0.2-2.4,0.5-4,0.5c-4.8,0-5-3.2-5.1-5.9h-0.1c-1.5,2.2-4.7,6.6-15.5,6.6c-5.7,0-12.9-2.5-12.9-9.9
		c0-9,9.2-10,16.5-10.4c10.3-0.6,11.6-1.1,11.6-4.4c0-4.8-3.2-6.5-11-6.5c-7.5,0-11,2-11.4,7.3H223.9z M250.5,319.4
		c-2.1,1.3-5.3,1.6-14.7,2.5c-5.5,0.5-8.7,2.2-8.7,6.1c0,5.2,4.5,6.7,9.6,6.7c6.4,0,13.8-3.4,13.8-10.3V319.4z'
        />
        <path
          className='st0'
          d='M314.2,316.2c0-6.1-3.1-9.2-9.9-9.2c-7.3,0-11.3,4.2-11.3,10.6v19.6h-4.7v-22.5c0-4.2-3-7.8-9.4-7.8
		c-6.8,0-11.7,3.9-11.7,11.5v18.7h-4.7v-33.2h4.7v6.1h0.1c2-3.5,5.6-7.1,13.1-7.1c7.5,0,10.9,4,11.9,7.3c1.6-3.3,6.4-7.3,13-7.3
		c9.3,0,13.6,4.7,13.6,11.5v22.6h-4.7V316.2z'
        />
        <path
          className='st0'
          d='M325.6,291.4h4.7v19.4h0.1c2.2-4.2,7.2-7.7,13.5-7.7c12.4,0,18.1,8.4,18.1,17.6c0,8.1-4.5,17.6-18.1,17.6
		c-8,0-12.2-4.8-13.5-7.6h-0.1v6.6h-4.7V291.4z M343.9,334.3c9.6,0,13.3-7,13.3-13.6c0-8.4-5.9-13.7-13.3-13.7
		c-9.6,0-13.9,6.8-13.9,13.7C330,329.4,336.1,334.3,343.9,334.3'
        />
        <path
          className='st0'
          d='M367.2,314.3c0.7-6.9,4.5-11.2,15.8-11.2c11.1,0,15.2,3.4,15.2,9.8v17.3c0,2.7,0.8,3.7,2.6,3.7
		c0.8,0,1.7-0.1,2.5-0.1v3.2c-1,0.2-2.4,0.5-4,0.5c-4.8,0-5-3.2-5.1-5.9h-0.1c-1.5,2.2-4.7,6.6-15.5,6.6c-5.7,0-12.9-2.5-12.9-9.9
		c0-9,9.2-10,16.5-10.4c10.3-0.6,11.6-1.1,11.6-4.4c0-4.8-3.2-6.5-11-6.5c-7.5,0-11,2-11.4,7.3H367.2z M393.9,319.4
		c-2.1,1.3-5.3,1.6-14.7,2.5c-5.5,0.5-8.7,2.2-8.7,6.1c0,5.2,4.5,6.7,9.6,6.7c6.4,0,13.8-3.4,13.8-10.3V319.4z'
        />
        <path
          className='st1'
          d='M413.6,326.7c0.3,5.1,6.1,5.9,9.3,5.9c4.4,0,9.2-1.2,9.2-4.3c0-2.6-1.5-3.5-11-4.8c-10.5-1.4-16-2.6-16-10.3
		c0-9.6,11.2-10.9,16.9-10.9c5.2,0,17.8,0.8,18.3,11.6h-9.2c-0.7-5-6.6-5.6-9.6-5.6c-5,0-7.7,2.1-7.7,3.9c0,2.7,0.8,3.2,9.3,4.4
		c11.1,1.6,18.4,2.7,18.4,10.5c0,8.6-8.2,11.5-18.7,11.5c-7.2,0-18.6-1.5-18.9-11.9H413.6z'
        />
        <path
          className='st1'
          d='M482.7,329.6c0,1.8,1.3,1.9,1.9,1.9c0.8,0,2.2-0.1,2.9-0.2v6.2c-1.7,0.3-3.3,0.8-6.2,0.8
		c-5.7,0-6.4-3.1-6.6-4.3c-1.7,1.6-6,4.6-15.9,4.6c-8.8,0-13.8-4-13.8-10.6c0-9.3,9.6-10.1,15.2-10.7c12.6-1.2,14-1.7,14-4.3
		c0-3.9-4.5-4.8-9.6-4.8c-5.8,0-8.9,1.8-9.2,5.6h-8.6c1.3-8.9,7.8-11.6,17.9-11.6c17.7,0,18.2,7,18.2,11.4V329.6z M473.9,320.4
		c-2.6,1.1-5.3,1.4-11.5,2.1c-6.6,0.8-8.2,2.2-8.2,5.1c0,4.5,4.1,5.3,7.1,5.3c4.7,0,12.7-1.8,12.7-9.3V320.4z'
        />
        <path
          className='st1'
          d='M493.4,309.8h-6.9v-6.4h6.9v-4.3c0-3.8,1-8.7,10.1-8.7c3.8,0,5.9,0.1,7.9,0.3v6.4c-1-0.1-2.9-0.3-4.5-0.3
		c-4,0-4.7,1-4.7,4v2.6h9.2v6.4h-9.2v27.4h-8.8V309.8z'
        />
        <path
          className='st1'
          d='M521.7,322.6c0.2,3.8,2.7,9.6,11.5,9.6c5.8,0,8.4-2,10.1-4.8h9.6c-1.9,3.8-6.5,11.2-19.4,11.2
		c-15.2,0-20.9-8.9-20.9-17.9c0-11.3,8.4-18.5,20.3-18.5c15,0,20.7,9.2,20.7,19.1v1.3H521.7z M544.5,317c-1.1-6.8-7.1-8.8-11.4-8.8
		c-4.1,0-9.8,1.9-11.3,8.8H544.5z'
        />
        <path
          className='st1'
          d='M561.8,309.8h-7v-6.4h7V293h8.8v10.5h9.2v6.4h-9.2v15.4c0,3.9,0.2,6,3.2,6c2.2,0,4.2-0.1,6.3-0.1v6.4
		c-2.4,0.3-4.1,0.5-6.8,0.5c-10.7,0-11.5-3.8-11.5-10.2V309.8z'
        />
        <path
          className='st1'
          d='M580.2,303.5h9.6l10.8,24.2l10.6-24.2h9.4l-18.4,39.8c-2.7,5.8-4.9,6.9-12.2,6.9c-1.5,0-3.8-0.1-5.5-0.1v-6.8
		c0.7,0.1,2.5,0.1,4.4,0.1c2.4,0,4.1-0.5,5.1-2.6l2-4.1L580.2,303.5z'
        />
      </g>
      <g>
        <defs>
          <path
            id='SVGID_1_'
            d='M31.3,276.3c-3.9,5.1-7,10.6-9.4,16.3c14.4,58.7,101.3,22.2,114.5,82.4c3.7-3.2,7.1-6.8,10.2-10.8
			c4-5.2,7.1-10.8,9.5-16.6c-14.3-58.7-101-22.5-114.5-82.3C37.8,268.6,34.4,272.3,31.3,276.3'
          />
        </defs>
        <defs>
          <path
            id='SVGID_2_'
            d='M66.6,251.3c12.3,32.9,79,23.8,90.5,44.3c-5-13.9-14.3-26.4-27.5-35.4c-12.5-8.4-26.6-12.5-40.6-12.5
			C81.4,247.8,73.8,249,66.6,251.3'
          />
        </defs>
        <defs>
          <path
            id='SVGID_3_'
            d='M48.2,380.3c19.4,13.1,42.8,15.6,63.4,8.8c-13.1-31.5-63.6-25.6-91.1-44.7
			C25.5,358.5,34.9,371.3,48.2,380.3'
          />
        </defs>
        <use xlinkHref='#SVGID_1_' overflow='visible' fill='#1B75BA' />
        <use xlinkHref='#SVGID_2_' overflow='visible' fill='#414141' />
        <use xlinkHref='#SVGID_3_' overflow='visible' fill='#414141' />
      </g>
    </svg>
  );
}

export default SambaSafety;
