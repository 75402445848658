import {
  DropdownField,
  InlineTooltip,
  Switch,
  TextField
} from '../../../../components/Core';
import { PlusIcon } from '../../../../components/Icons';
import { ENTITY_DATA, getDefaultEntity, GROUP_TYPE } from '../../utils';
import Entity from '../Entity';
import classNames from 'classnames';
import styles from '../styles.module.scss';
import { Button } from '../../../../components/Core/Button/button';
import PageFilter from './PageFilter';
import GroupType from '../GroupType';
import { forwardRef } from 'react';

const TYPE_OPTIONS = [
  { value: 'one_value', display: 'Single Value' },
  {
    value: 'multiple_value',
    display: 'Multiple or Related Values'
  },
  { value: 'yes_no', display: 'Yes or No' },
  {
    value: 'page_number',
    display: 'Page Numbers'
  }
];
export const DocumentQuestionGroup = forwardRef(
  (
    {
      group,
      onUpdate,
      isEmailIntegActive,
      index,
      fieldProperties,
      hiddenFieldProperties
    }: any,
    ref: any
  ) => {
    const showPageFilterSection =
      group.question_type === 'one_value' ||
      group.question_type === 'multiple_value' ||
      group.question_type === 'yes_no';

    const showMultipleValueSettings = ![
      'one_value',
      'page_number',
      'yes_no'
    ].includes(group.question_type);

    return (
      <div
        ref={ref}
        className={classNames(styles.keyValuePairs, styles.moreGap)}
      >
        <GroupType group={group} onUpdate={onUpdate} options={TYPE_OPTIONS} />
        {showMultipleValueSettings && (
          <MultipleValueSettings group={group} onUpdate={onUpdate} />
        )}
        {isEmailIntegActive && (
          <EmailSettings group={group} onUpdate={onUpdate} />
        )}
        <div className={styles.keyValuePair}>
          <div className={styles.entitiesContainer}>
            {group.entities?.map((entity: any, entityIndex: number) => (
              <Entity
                key={`group-${index}-entity-${entityIndex + 1}`}
                type={group.question_type}
                entity={entity}
                fieldProperties={fieldProperties}
                hiddenFieldProperties={hiddenFieldProperties}
                onUpdate={(key: string | object, value?: any) => {
                  const updatedEntities = [...group.entities];
                  updatedEntities[entityIndex] =
                    typeof key !== 'string'
                      ? {
                          ...entity,
                          ...key
                        }
                      : {
                          ...entity,
                          [key]: value
                        };
                  if (key === 'type') {
                    if (value !== 'singleOption') {
                      updatedEntities[entityIndex].options = [];
                      updatedEntities[entityIndex].default_option = '';
                    }
                    if (value !== 'freeForm') {
                      updatedEntities[entityIndex].examples = [];
                    }
                  }
                  onUpdate('entities', updatedEntities);
                }}
                onRemove={() => {
                  const updatedEntities = [...group.entities];
                  updatedEntities.splice(entityIndex, 1);
                  if (updatedEntities.length === 0) {
                    updatedEntities.push(getDefaultEntity());
                  }
                  onUpdate('entities', updatedEntities, entityIndex, 'remove');
                }}
                {...ENTITY_DATA[group.question_type as GROUP_TYPE]}
              />
            ))}
          </div>
        </div>
        {group.question_type === 'multiple_value' && (
          <div className={styles.keyValuePair}>
            <Button
              variant='dashed'
              onClick={(e) => {
                e.preventDefault();
                const newEntities = [
                  ...(group.entities || []),
                  getDefaultEntity()
                ];
                onUpdate(
                  'entities',
                  newEntities,
                  newEntities.length - 1,
                  'add'
                );
              }}
            >
              <PlusIcon className={styles.icon} width={12} />
              <span>Add Entity</span>
            </Button>
          </div>
        )}
        {showPageFilterSection && (
          <PageFilter group={group} onUpdate={onUpdate} />
        )}
      </div>
    );
  }
);
DocumentQuestionGroup.displayName = 'DocumentQuestionGroup';

function MultipleValueSettings({ group, onUpdate, disabled }: any) {
  return (
    <div style={{ marginBottom: '8px' }}>
      <div className={styles.keyValuePair}>
        <div className={styles.key} style={{ width: '190px' }}>
          Multiple Occurrences
          <InlineTooltip
            size={15}
            style={{ marginTop: '-2px' }}
            text='Return one or multiple occurences of a group of related entities.'
          />
        </div>
        <div className={styles.value} style={{ marginTop: '8px' }}>
          <Switch
            id='extraction-question-group-list-toggle'
            checked={
              group.properties?.single_occurrence === undefined
                ? true
                : !group.properties?.single_occurrence
            }
            onCheckedChange={(checked) => {
              onUpdate('properties', {
                ...(group.properties || {}),
                single_occurrence: !checked
              });
            }}
            disabled={disabled}
          />
        </div>
      </div>
      <div className={styles.keyValuePair}>
        <div className={styles.key} style={{ width: '150px' }}>
          Query Details{' '}
          <InlineTooltip text='(Optional) Description & constraints for the overall query' />
        </div>
        <div className={styles.value}>
          <TextField
            placeholder='Only return active account assets'
            className={styles.controlField}
            onComplete={(newVal: string) => {
              onUpdate('criteria', newVal);
            }}
            value={group.criteria}
            disabled={disabled}
          />
        </div>
      </div>
    </div>
  );
}

function EmailSettings({ group, onUpdate, disabled }: any) {
  return (
    <div
      className={styles.keyValuePair}
      style={{ display: 'flex', alignItems: 'center' }}
    >
      <div className={styles.key} style={{ width: '134px' }}>
        Run on Email
        <InlineTooltip text='Run the query on the email itself (subject and body) and/or the attachments found within the email. If running on both and a value is found on the email, the query will not run on the attachments.' />
      </div>
      <div className={styles.value}>
        <DropdownField
          value={group.run_email_mode}
          options={[
            { value: 'document_only', display: 'Attachments Only' },
            { value: 'email_only', display: 'Email Content Only' },
            {
              value: 'email_document',
              display: 'Email Content, then Attachments'
            }
          ]}
          onChange={(event: any) => {
            const { value } = event.target;
            onUpdate('run_email_mode', value);
          }}
          disabled={disabled}
        />
      </div>
    </div>
  );
}
