import classNames from 'classnames';
import { Fragment, useState } from 'react';

import styles from '../styles.module.scss';
import { EditIcon } from '../../../components/Icons';
import EditUserModal from '../modals/EditUserModal';
import { useAppSelector } from '../../../hooks';
import AccountGroupsModal from '../modals/AccountGroupsModal';
import AllUserGroupsModal from '../modals/AllUserGroupsModal';
import { roleValueToLabel } from './PermissionsConfig';
import { Chip } from '../../../components/Core/Chip';
import { Button } from '../../../components/Core/Button/button';

const defaultActiveEdit = {
  email: '',
  role: '',
  permissionEditFormResults: true,
  permissionInviteCollaborators: true,
  permissionEditCollaboratorTemplate: true,
  permissionEditFormDesign: true,
  permissionEditLogic: true,
  permissionEditTheme: true,
  permissionDeleteForms: true,
  permissionPublishForms: true,
  permissionEditLinkedProperties: true,
  permissionFilterResults: null,
  groupIds: [],
  firstName: '',
  lastName: '',
  customAttributes: []
};

const defaultGroupEdit = {
  email: '',
  groups: []
};

export default function UserList({ curAccount, displayAccounts, label }: any) {
  const [activeEdit, setActiveEdit] = useState(defaultActiveEdit);
  const [activeGroupEdit, setActiveGroupEdit] = useState(defaultGroupEdit);
  const [editAllUserGroups, setEditAllUserGroups] = useState(false);
  const org = useAppSelector((state) => state.accounts.organization);
  const groupMap = org?.all_user_groups.reduce(
    (groups: any, group: any) => ({
      ...groups,
      [group.id]: group.name
    }),
    {} as Record<string, string>
  );
  const isAdmin = curAccount.role === 'admin';
  const enterprise = org?.tier >= 4;
  const editablePermissions = isAdmin && enterprise;

  const curEmail = curAccount.email;

  function getNamesForGroupIds(groupIds: string[]): string[] {
    return groupIds.map((groupId: string) => groupMap[groupId]);
  }

  return displayAccounts.length > 0 ? (
    <>
      <EditUserModal
        key={activeEdit.email}
        {...activeEdit}
        close={() => setActiveEdit(defaultActiveEdit)}
      />
      <AccountGroupsModal
        {...activeGroupEdit}
        close={() => setActiveGroupEdit(defaultGroupEdit)}
      />
      <AllUserGroupsModal
        show={editAllUserGroups}
        close={() => setEditAllUserGroups(false)}
      />
      <div className={styles.userListSeparator}>
        <label className={styles.settingsLabel} style={{ width: '70px' }}>
          {label}
        </label>
        <label className={styles.settingsLabel}>Role</label>
        <div className={styles.settingsLabelContainer}>
          <label className={styles.settingsLabel}>User Groups</label>
          {editablePermissions && (
            <EditIcon
              key='editIcon'
              className={styles.roleEditIcon}
              width={18}
              height={18}
              onClick={() => setEditAllUserGroups(true)}
            />
          )}
        </div>
      </div>
      {displayAccounts
        .sort((a: any, b: any) =>
          a.email === curEmail ? -1 : b.email === curEmail ? 1 : 0
        )
        .map(
          ({
            email,
            role,
            user_groups: groups,
            permission_edit_form_results,
            permission_invite_collaborators,
            permission_edit_collaborator_template,
            permission_filter_results,
            permission_edit_form_design,
            permission_edit_logic,
            permission_edit_theme,
            permission_delete_forms,
            permission_publish_forms,
            permission_edit_linked_properties,
            first_name,
            last_name,
            custom_attributes
          }: any) => {
            return (
              <Fragment key={email}>
                <div
                  className={classNames(
                    styles.sectionSeparator,
                    styles.spacedNarrow
                  )}
                />
                <div className={styles.teamRow}>
                  <div className={styles.teamControls}>
                    <div className={styles.teamEmail}>{email}</div>
                    <div className={styles.teamRole}>
                      {roleValueToLabel[role]}
                    </div>
                    <div className={styles.teamGroups}>
                      {getNamesForGroupIds(groups).map((groupName, index) => (
                        <Chip label={groupName} key={`${groupName}-${index}`} />
                      ))}
                    </div>
                  </div>
                  {(isAdmin || curEmail === email) && (
                    <Button
                      variant='outline-primary'
                      onClick={() =>
                        setActiveEdit({
                          email,
                          role,
                          permissionEditFormResults:
                            permission_edit_form_results,
                          permissionInviteCollaborators:
                            permission_invite_collaborators,
                          permissionFilterResults: permission_filter_results,
                          permissionEditFormDesign: permission_edit_form_design,
                          permissionEditLogic: permission_edit_logic,
                          permissionEditCollaboratorTemplate:
                            permission_edit_collaborator_template,
                          permissionEditTheme: permission_edit_theme,
                          permissionDeleteForms: permission_delete_forms,
                          permissionPublishForms: permission_publish_forms,
                          permissionEditLinkedProperties:
                            permission_edit_linked_properties,
                          groupIds: groups,
                          firstName: first_name,
                          lastName: last_name,
                          customAttributes: custom_attributes
                        })
                      }
                    >
                      Edit
                    </Button>
                  )}
                </div>
              </Fragment>
            );
          }
        )}
      <div
        className={classNames(styles.sectionSeparator, styles.spacedNarrow)}
      />
    </>
  ) : null;
}
