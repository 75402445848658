import {
  DropdownMultiField,
  InlineTooltip,
  TextField
} from '../../../components/Core';
import Label from '../../../components/Dialog/Label';
import styles from '../styles.module.scss';
import { v4 as uuidv4 } from 'uuid';

import '../../../style/dialog-form.css';
import VariantGroup from './VariantGroup';

export default function VariantProperties({
  formData,
  handleChange,
  fileOptions,
  hiddenFieldOptions,
  usedFields,
  fieldProperties,
  hiddenFieldProperties,
  variant,
  fieldNames
}: any) {
  const updateQuestionGroupFields = (
    groupId: string,
    entityIndex: number,
    newFieldId: string | null
  ) => {
    const updatedGroupVariants = [...(variant.group_variants || [])];

    const existingVariantIndex = updatedGroupVariants.findIndex(
      (go) => go.question_group_id === groupId
    );

    if (existingVariantIndex === -1) {
      const matchingGroup = formData.question_groups.find(
        (g: any) => g.id === groupId
      );
      if (matchingGroup) {
        const newFieldIds = Array(matchingGroup.entities.length).fill(null);
        newFieldIds[entityIndex] = newFieldId;

        updatedGroupVariants.push({
          id: uuidv4(),
          question_group_id: groupId,
          field_ids: newFieldIds
        });
      }
    } else {
      const existingVariant = updatedGroupVariants[existingVariantIndex];
      const newFieldIds = [...existingVariant.field_ids];

      while (newFieldIds.length <= entityIndex) {
        newFieldIds.push(null);
      }

      newFieldIds[entityIndex] = newFieldId;

      updatedGroupVariants[existingVariantIndex] = {
        ...existingVariant,
        field_ids: newFieldIds
      };
    }

    handleChange({ group_variants: updatedGroupVariants });
  };

  return (
    <>
      <Label>Name</Label>
      <TextField
        value={variant.key}
        placeholder='Variant'
        onComplete={(newKey: string) => handleChange({ key: newKey })}
        className={styles.modalInput}
        required
      />
      <div className={styles.labelContainer}>
        <Label>Uploaded File Sources</Label>
        <InlineTooltip text='The extraction will run on files stored in these fields' />
      </div>
      <DropdownMultiField
        selected={variant.file_sources}
        options={[...fileOptions, ...hiddenFieldOptions].filter(
          (option) =>
            formData.file_sources.includes(option.value) ||
            !usedFields.includes(option.value)
        )}
        onChange={(items: { value: string }[]) =>
          handleChange({ file_sources: items.map((item) => item.value) })
        }
        className={styles.modalInput}
        required
      />

      <div className={styles.labelContainer}>
        <Label>Query Overrides</Label>
      </div>
      <div className='space-y-4'>
        {formData.question_groups.map((group: any, index: number) => {
          return (
            <VariantGroup
              key={group.id}
              index={index}
              group={group}
              fieldProperties={fieldProperties}
              hiddenFieldProperties={hiddenFieldProperties}
              file_type={formData.file_type}
              onUpdate={(entityIndex, newFieldId) =>
                updateQuestionGroupFields(group.id, entityIndex, newFieldId)
              }
              variant_group={variant.group_variants?.find(
                (i: any) => i.question_group_id === group.id
              )}
              fieldNames={fieldNames}
            />
          );
        })}
      </div>
    </>
  );
}
