function CyberCube({ width = 80, height = 80 }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 150 150'
    >
      <path fill='none' d='M0,0H150V150H0V0Z' />
      <path
        className='cls-1'
        fill='#18e06c'
        d='M89.99,90.44h-28.75v-28.75h28.75v10.66h46.85V14.83H14.38v122.77h122.45v-57.83h-46.85v10.66Z'
      />
    </svg>
  );
}

export default CyberCube;
