import styles from '../styles.module.scss';
import '../../../style/dialog-form.css';
import { Button } from '../../../components/Core/Button/button';
import { Negative } from '../../../components/Core/Button';
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import VariantProperties from './VariantProperties';
import { cn } from '../../../utils/cn';
import { CopyIcon } from '../../../components/Icons';
import useFeatheryRedux from '../../../redux';

export default function VariantList({
  formData,
  handleChange,
  fileOptions,
  hiddenFieldOptions,
  usedFields,
  fieldProperties,
  hiddenFieldProperties,
  fieldNames
}: any) {
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  const createVariant = () => {
    const new_index = formData.variants.length;
    handleChange({
      variants: [
        ...formData.variants,
        {
          key: '',
          id: uuidv4(),
          file_sources: [],
          group_variants: []
        }
      ]
    });

    return new_index;
  };

  const deleteVariant = (index: number) => {
    const new_variants = [
      ...(formData.variants as any[]).filter((_, i) => i !== index)
    ];

    handleChange({ variants: new_variants });
  };

  const changeVariant = (newData: Record<string, any>) => {
    if (selectedIndex == null) {
      return;
    }

    const new_variants = [...formData.variants];

    // Merge the changes into the variant at the selected index
    new_variants[selectedIndex] = {
      ...new_variants[selectedIndex],
      ...newData
    };

    handleChange({ variants: new_variants });
  };

  const isEditing = selectedIndex !== null;
  return (
    <>
      <div className={styles.sectionSeparator} />
      <div className={styles.lowerSection}>
        <div className={styles.customFieldContainer}>
          {!isEditing && (
            <ListPage
              variants={formData.variants}
              handleDelete={deleteVariant}
              setEditing={(index) => {
                if (index == null) {
                  index = createVariant();
                }
                setSelectedIndex(index);
              }}
            />
          )}

          {isEditing && (
            <>
              <Button
                variant='outline'
                className='mb-6'
                onClick={() => setSelectedIndex(null)}
              >
                Back
              </Button>
              <VariantProperties
                formData={formData}
                handleChange={changeVariant}
                fileOptions={fileOptions}
                hiddenFieldOptions={hiddenFieldOptions}
                usedFields={usedFields}
                fieldProperties={fieldProperties}
                hiddenFieldProperties={hiddenFieldProperties}
                variant={formData.variants[selectedIndex] || {}}
                fieldNames={fieldNames}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
}

interface ListPageProps {
  variants: any[];
  setEditing: (index: number | null) => void;
  handleDelete: (index: number) => void;
}

function ListPage({ variants, setEditing, handleDelete }: ListPageProps) {
  const {
    toasts: { addToast }
  } = useFeatheryRedux();

  return (
    <div>
      <div className={styles.listHeader}>
        <Button onClick={() => setEditing(null)}>Create Variant</Button>
      </div>
      <div className={styles.fieldsList}>
        {variants.length === 0 && (
          <div className={styles.listEmpty}>
            No variants yet. Add one to get started.
          </div>
        )}
        {variants.map((variant, index) => (
          <div key={index} className={styles.fieldListItem}>
            <div className={styles.fieldInfo}>
              {variant.key ? (
                <div>
                  <div className={styles.fieldLabel}>{variant.key}</div>
                  <div className='flex gap-1 items-center'>
                    <span
                      className='text-sm text-gray-700 cursor-pointer'
                      onClick={() => {
                        navigator.clipboard.writeText(variant?.id ?? '');
                        addToast({
                          text: 'ID copied to clipboard.'
                        });
                      }}
                    >
                      ID: {variant.id}
                    </span>
                    <Button
                      variant='ghost'
                      size='icon'
                      className='w-7 h-7'
                      onClick={() => {
                        navigator.clipboard.writeText(variant?.id ?? '');
                        addToast({
                          text: 'ID copied to clipboard.'
                        });
                      }}
                    >
                      <CopyIcon height={14} width={14} />
                    </Button>
                  </div>
                </div>
              ) : (
                <span className={cn(styles.fieldLabel, 'text-red-400')}>
                  Unnamed Variant
                </span>
              )}
            </div>
            <div className={styles.fieldActions}>
              <Button
                variant='outline'
                size='sm'
                onClick={() => setEditing(index)}
              >
                Edit
              </Button>
              <Negative size='sm' onClick={() => handleDelete(index)}>
                Delete
              </Negative>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
