import { CopyIcon, TrashIcon, UpArrowIcon } from '../../../components/Icons';
import type { FieldProperties } from '../ExtractionCreateModal/types';
import Label from '../../../components/Dialog/Label';
import classNames from 'classnames';
import styles from './styles.module.scss';
import useIntegrations from '../../../components/FormIntegrations/useIntegrations';
import { AI_INTEGRATION_TYPES } from '../../../components/AIIntegrations/types';
import { DocumentQuestionGroup } from './DocumentQuestionGroup';
import { CSVQuestionGroup } from './CSVQuestionGroup';
import { Button } from '../../../components/Core/Button/button';
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger
} from '../../../components/Core/Collapsible';
import { forwardRef, memo } from 'react';

type Props = {
  extractionId?: string | null;
  index: number;
  group: any;
  fieldProperties: Record<string, FieldProperties>;
  hiddenFieldProperties: Record<string, Partial<FieldProperties>>;
  isLast: boolean;
  file_type: string;
  fieldNames: Record<string, string>;
  onUpdate: (
    key: string,
    value: any,
    entityIndex?: any,
    operation?: 'add' | 'remove'
  ) => void;
  onRemove: () => void;
  onMoveUp: () => void;
  onMoveDown: () => void;
  onCopy: () => void;
};

function QuestionGroup(props: Props) {
  const {
    extractionId,
    index,
    group,
    fieldProperties,
    hiddenFieldProperties,
    isLast,
    file_type,
    fieldNames,
    onUpdate,
    onRemove,
    onMoveUp,
    onMoveDown,
    onCopy
  } = props;

  const integrations = useIntegrations({
    all: true,
    extractionId,
    entityType: 'ai'
  });

  const isEmailIntegActive = !!integrations[AI_INTEGRATION_TYPES.EMAIL_INBOX];
  return (
    <Collapsible defaultOpen={group.defaultOpen ?? false} asChild>
      <div
        key={`question-group-${index + 1}`}
        id={`group_${group.id}`}
        className={classNames(
          styles.questionGroupSection,
          styles.card,
          styles.white
        )}
      >
        <CollapsibleTrigger asChild>
          <QuestionGroupHeader
            entities={group.entities}
            index={index}
            isLast={isLast}
            fieldNames={fieldNames}
            onMoveUp={onMoveUp}
            onMoveDown={onMoveDown}
            onRemove={onRemove}
            onCopy={onCopy}
          />
        </CollapsibleTrigger>
        <CollapsibleContent asChild>
          {file_type === 'image' ? (
            <DocumentQuestionGroup
              group={group}
              onUpdate={onUpdate}
              isEmailIntegActive={isEmailIntegActive}
              index={index}
              fieldProperties={fieldProperties}
              hiddenFieldProperties={hiddenFieldProperties}
            />
          ) : (
            <CSVQuestionGroup
              group={group}
              onUpdate={onUpdate}
              index={index}
              fieldProperties={fieldProperties}
              hiddenFieldProperties={hiddenFieldProperties}
            />
          )}
        </CollapsibleContent>
      </div>
    </Collapsible>
  );
}

export default memo(QuestionGroup, (prev, next) => {
  return (
    prev.extractionId === next.extractionId &&
    prev.index === next.index &&
    prev.group === next.group &&
    prev.fieldProperties === next.fieldProperties &&
    prev.hiddenFieldProperties === next.hiddenFieldProperties &&
    prev.isLast === next.isLast &&
    prev.file_type === next.file_type &&
    prev.fieldNames === next.fieldNames
  );
});

const QuestionGroupHeader = forwardRef(
  (
    {
      entities,
      fieldNames,
      index,
      isLast,
      onMoveUp,
      onMoveDown,
      onRemove,
      onCopy,
      ...props
    }: any,
    ref
  ) => {
    return (
      <div ref={ref} className={styles.header} {...props}>
        <div className={styles.labelContainer}>
          {entities.map((entity: any, index: number) => (
            <Label key={index} className={styles.label}>
              <p className='line-clamp-1'>{entity.name || `Unnamed Entity`}</p>
              <p className={styles.fieldName}>
                ({fieldNames[entity.field_id] ?? 'Unmapped'})
              </p>
            </Label>
          ))}
        </div>
        <div className={styles.actionContainer}>
          <Button
            variant='gray'
            size='icon'
            disabled={index <= 0}
            className='rounded-full !w-[25px] !h-[25px] hover:text-primary'
            onClick={(event) => {
              onMoveUp();
              event.stopPropagation();
            }}
          >
            <UpArrowIcon color='currentColor' width={16} height={16} />
          </Button>
          <Button
            variant='gray'
            size='icon'
            disabled={isLast}
            className='rounded-full !w-[25px] !h-[25px] hover:text-primary'
            onClick={(event) => {
              onMoveDown();
              event.stopPropagation();
            }}
          >
            <UpArrowIcon
              color='currentColor'
              className='rotate-180'
              width={16}
              height={16}
            />
          </Button>
          <Button
            variant='gray'
            size='icon'
            className='rounded-full !w-[25px] !h-[25px] hover:text-primary'
            onClick={(event) => {
              onCopy();
              event.stopPropagation();
            }}
          >
            <CopyIcon color='currentColor' width={16} height={16} />
          </Button>
          <Button
            variant='gray'
            size='icon'
            className='rounded-full !w-[25px] !h-[25px] hover:text-primary'
            onClick={(event) => {
              onRemove();
              event.stopPropagation();
            }}
          >
            <TrashIcon color='currentColor' width={16} height={16} />
          </Button>
        </div>
      </div>
    );
  }
);

QuestionGroupHeader.displayName = 'QuestionGroupHeader';
