import { Fragment, memo, useEffect, useState } from 'react';
import { DropdownMultiField } from '../../../components/Core';
import { HorizontalFormAttribute } from '../../../components/Core/HorizontalFormAttribute';
import Dialog from '../../../components/Dialog';
import { usePermissionsConfig } from '../../../hooks/usePermissionsConfig';
import RoleDropdown from '../components/RoleDropdown';
import styles from '../styles.module.scss';
import attributeStyles from '../../../components/Core/HorizontalFormAttribute/styles.module.scss';
import { PermissionsToggles } from '../components/PermissionsToggles';
import useModalSubmissionLockout from '../../../utils/useModalSubmissionLockout';
import { Neutral, Positive } from '../../../components/Core/Button';
import useFeatheryRedux from '../../../redux';
import { sentryError } from '../../../utils/runtime';
import { useConfirm } from '../../../hooks/useConfirm';
import { useAppSelector } from '../../../hooks';
import { WarningIcon } from '../../../components/Icons';
import { Spacer } from '../../../components/Spacer';
import { v4 as uuidv4 } from 'uuid';
import { Button } from '../../../components/Core/Button/button';

function EditUserModal({
  email,
  role = 'admin',
  permissionEditFormResults,
  permissionInviteCollaborators,
  permissionEditCollaboratorTemplate,
  permissionEditTheme,
  permissionDeleteForms,
  permissionPublishForms,
  permissionEditLinkedProperties,
  permissionFilterResults,
  permissionEditLogic,
  permissionEditFormDesign,
  close,
  firstName: fn,
  lastName: ln,
  groupIds = [],
  customAttributes: userCustomAttributes = {}
}: any) {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  const [error, setError] = useState('');

  const org = useAppSelector((state) => state.accounts.organization);
  const account = useAppSelector((state) => state.accounts.account);
  const isAccountOwner = email === account.email;
  const isAccountOwnerOrAdmin = isAccountOwner || account.role === 'admin';

  const [customAttributeLabels, setCustomAttributeLabels] = useState<
    { id: string; label: string }[]
  >(org?.custom_attributes || []);

  const [customAttributeValues, setCustomAttributeValues] = useState<
    Record<string, string>
  >(userCustomAttributes || {});

  useEffect(() => {
    setFirstName(fn);
    setLastName(ln);
  }, [fn, ln]);

  const {
    getSubmitFunction: _,
    submissionData: permissionsSubmissionData,
    ...permissionsConfigObjects
  } = usePermissionsConfig({
    role,
    permissionEditFormResults,
    permissionInviteCollaborators,
    permissionEditCollaboratorTemplate,
    permissionEditTheme,
    permissionDeleteForms,
    permissionPublishForms,
    permissionEditLinkedProperties,
    permissionFilterResults,
    permissionEditLogic,
    permissionEditFormDesign,
    showUserGroups: true,
    groupIds
  });

  const {
    editAccount,
    editOrganization,
    removeUser,
    toasts: { addErrorToast }
  } = useFeatheryRedux();

  const { confirm: confirmAndDeleteUser } = useConfirm({
    message: 'Are you sure you want to remove this account?',
    onConfirm: () => removeUser({ email }).finally(close),
    onAbort: () => null
  });

  useEffect(() => {
    if (error) {
      validate();
    }
  }, [error, customAttributeLabels]);

  const validate = () => {
    const labels = new Set(['email', 'first_name', 'last_name']);
    const transform = (str: string) => str.toLowerCase().replace(' ', '_');

    for (const { label } of customAttributeLabels) {
      if (!label) {
        setError('One or more attributes missing a label');
        return false;
      }

      const transformed = transform(label);
      if (labels.has(transformed)) {
        setError(
          `Attribute label cannot be the same as another label: ${label}`
        );
        return false;
      }
      labels.add(transform(label));
    }

    setError('');
    return true;
  };

  const submit = async () => {
    if (!validate()) {
      return;
    }

    let submissionData = permissionsSubmissionData;
    if (firstName) {
      submissionData = { ...submissionData, first_name: firstName };
    }
    if (lastName) {
      submissionData = { ...submissionData, last_name: lastName };
    }
    if (customAttributeValues) {
      submissionData = {
        ...submissionData,
        custom_attributes: customAttributeValues
      };
    }

    try {
      await editOrganization({
        custom_attributes: customAttributeLabels
      });
    } catch (e: any) {
      sentryError(e, {
        custom_attributes: customAttributeLabels,
        account_email: email
      });
      addErrorToast({ title: 'Could not update organization attributes' });
      return;
    }

    try {
      await editAccount({ ...submissionData, account_email: email });
    } catch (e: any) {
      sentryError(e, {
        submissionData,
        account_email: email
      });
      addErrorToast({ title: 'Could not update user account' });
      return;
    } finally {
      close();
    }
  };

  const { lockOutFlag, lockoutFunction } = useModalSubmissionLockout(submit);

  return (
    <Dialog isOpen={email && role} title='Edit User' size='md' onClose={close}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          return lockoutFunction();
        }}
      >
        <div style={{ height: '100%' }}>
          {/* an admin or account owner can edit the metadata */}
          <h4 className={styles.sectionTitle}>Role & Groups</h4>
          <HorizontalFormAttribute
            showTooltip
            labelProps={{
              value: 'Email',
              disabled: true
            }}
            inputProps={{
              disabled: true,
              value: email,
              placeholder: 'user@company.com',
              onChange: () => null // not needed since email is disabled
            }}
          />
          <Spacer />
          <HorizontalFormAttribute
            labelProps={{
              value: 'Role',
              disabled: true
            }}
            fieldOverride={
              <RoleDropdown
                disabled={isAccountOwner}
                showUserGroups={false}
                permissionsConfigObjects={permissionsConfigObjects}
                hidePermissionsToggles
                hideLabel
                dropDownClass={attributeStyles.attributeFieldDropDown}
              />
            }
          />
          <Spacer />
          <HorizontalFormAttribute
            labelProps={{
              value: 'Groups',
              disabled: true
            }}
            fieldOverride={
              <DropdownMultiField
                className={attributeStyles.attributeFieldDropDownMulti}
                placeholder='Select a User Group'
                disabled={permissionsConfigObjects.allGroups.length < 1}
                selected={permissionsConfigObjects.curGroups}
                options={permissionsConfigObjects.allGroups.map(
                  (group: any) => ({
                    value: group.id,
                    label: group.name
                  })
                )}
                onChange={(items: { value: string }[]) =>
                  permissionsConfigObjects.setCurGroups(
                    items.map((item) => item.value)
                  )
                }
              />
            }
          />
          <br />
          <h4 className={styles.sectionTitle}>Permissions</h4>
          <PermissionsToggles
            styles={{ marginBottom: '10px' }}
            curRole={permissionsConfigObjects.curRole}
            permissions={permissionsConfigObjects.permissions}
          />
          {permissionsConfigObjects.curRole === 'admin' && (
            <p className={styles.sectionParagraph}>
              Admins have full permissions.
            </p>
          )}
          <br />
          <h4 className={styles.sectionTitle}>Account Attributes</h4>
          {error && <p style={{ color: 'red' }}>{error}</p>}
          <HorizontalFormAttribute
            showTooltip
            labelProps={{
              value: 'First Name',
              disabled: true
            }}
            inputProps={{
              value: firstName,
              placeholder: 'Jane',
              onChange: (val: string) => setFirstName(val),
              disabled: !isAccountOwnerOrAdmin
            }}
          />
          <Spacer />
          <HorizontalFormAttribute
            showTooltip
            labelProps={{
              value: 'Last Name',
              disabled: true
            }}
            inputProps={{
              value: lastName,
              placeholder: 'Doe',
              onChange: (val: string) => setLastName(val),
              disabled: !isAccountOwnerOrAdmin
            }}
          />
          <Spacer />
          {customAttributeLabels.map(
            ({ id: attributeId, label: attributeLabel }) => (
              <Fragment key={attributeId}>
                <HorizontalFormAttribute
                  showTooltip
                  labelProps={{
                    placeholder: 'New Attribute',
                    value: attributeLabel,
                    onChange: (val: string) => {
                      setCustomAttributeLabels(
                        customAttributeLabels.map(({ id, label }) =>
                          id === attributeId
                            ? { id, label: val }
                            : { id, label }
                        )
                      );
                    }
                  }}
                  inputProps={{
                    value: customAttributeValues[attributeId] ?? '',
                    placeholder: 'Value',
                    onChange: (val: string) => {
                      setCustomAttributeValues({
                        ...customAttributeValues,
                        [attributeId]: val
                      });
                    },
                    disabled: !isAccountOwnerOrAdmin
                  }}
                  onDelete={() => {
                    setCustomAttributeLabels(
                      customAttributeLabels.filter(
                        (label) => label.id !== attributeId
                      )
                    );
                  }}
                />
                <Spacer />
              </Fragment>
            )
          )}
          <Neutral
            disabled={
              !permissionsConfigObjects.isEnterprise || account.role !== 'admin'
            }
            style={{ margin: 0 }}
            onClick={() => {
              setCustomAttributeLabels([
                ...customAttributeLabels,
                {
                  id: uuidv4(),
                  label: ''
                }
              ]);
            }}
          >
            Add attribute
          </Neutral>
        </div>
        <br />
        <div
          className='dialog-form-action text-center'
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexDirection: 'row'
          }}
        >
          {!isAccountOwner ? (
            <Button
              variant='ghost'
              className='!text-destructive'
              type='button'
              onClick={confirmAndDeleteUser}
            >
              <WarningIcon height={17} width={17} color='currentColor' />
              Remove user from {org?.name || 'Organization'}
            </Button>
          ) : (
            <div />
          )}
          <Positive lockoutOverride={lockOutFlag}>Save</Positive>
        </div>
      </form>
    </Dialog>
  );
}

export default memo(EditUserModal);
