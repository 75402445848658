function MotorCheck({ width = 140, height = 50 }) {
  return (
    <img
      alt='MotorCheck logo'
      src={require('./MotorCheck.png')}
      style={{ width, height }}
    />
  );
}

export default MotorCheck;
