import { v4 as uuidv4 } from 'uuid';

export const getDefaultEntity = () => ({
  name: '',
  criteria: '',
  field_id: null,
  options: [],
  type: '',
  examples: [],
  default_option: '',
  required: true,
  unique: false
});

export const defaultQuestionGroup = (type = 'image', copy?: any): any => {
  let template = {
    question_type: type === 'image' ? 'one_value' : 'column_value',
    criteria: '',
    run_email: false,
    run_email_mode: 'document_only',
    entities: [getDefaultEntity()],
    defaultOpen: true
  };

  if (copy) {
    template = {
      ...template,
      ...JSON.parse(JSON.stringify(copy))
    };

    template.entities.forEach((entity) => (entity.field_id = null));
  }
  return {
    ...template,
    id: uuidv4(),
    field_ids: []
  };
};

export const getDefaultQuestionGroups = (extraction: any) => {
  return extraction?.question_groups?.length
    ? extraction.question_groups.map((group: any) => {
        return {
          ...group,
          entities: group.entities
            ? group.entities.map((entity: any, i: number) => ({
                ...entity,
                field_id: group.field_ids[i]
              }))
            : [getDefaultEntity()],
          toggle_page_question: !!group.page_question
        };
      })
    : [defaultQuestionGroup()];
};

export const questionGroupTemplates = {
  holdings: () => [
    {
      id: uuidv4(),
      question_type: 'one_value',
      field_ids: [],
      criteria: '',
      run_email_mode: 'document_only',
      entities: [
        {
          name: 'Account Holder or Trustee First Name',
          criteria:
            'The first name of the person, account holder, or trustee of the company.',
          field_id: null,
          options: [],
          default_option: '',
          required: true,
          unique: false
        }
      ]
    },
    {
      id: uuidv4(),
      question_type: 'one_value',
      field_ids: [],
      criteria: '',
      run_email_mode: 'document_only',
      entities: [
        {
          name: 'Account Holder or Trustee Last Name',
          criteria:
            'The last name of the person, account holder, or trustee of the company.',
          field_id: null,
          options: [],
          default_option: '',
          required: true,
          unique: false
        }
      ]
    },
    {
      id: uuidv4(),
      question_type: 'multiple_value',
      field_ids: [],
      criteria:
        'Current details of all active securities invested in by the account. Do not return transaction, activity, interest, dividend, loss/gain details or portfolio allocations/summaries. Ignore "Top Holdings" tables.',
      run_email_mode: 'document_only',
      entities: [
        {
          name: 'Holding Name',
          criteria: 'Full name of the active holding. For example "Apple Inc".',
          field_id: null,
          options: [],
          default_option: '',
          required: true,
          unique: true
        },
        {
          name: 'Holding Type',
          criteria: 'The type of the holding.',
          field_id: null,
          options: [
            { label: 'Stock', value: 'Stock' },
            { label: 'Mutual Fund', value: 'Mutual Fund' },
            { label: 'Bond', value: 'Bond' },
            { label: 'Treasury', value: 'Treasury' },
            { label: 'Cash', value: 'Cash' },
            { label: 'Option', value: 'Option' },
            { label: 'Other', value: 'Other' }
          ],
          default_option: '',
          required: true,
          unique: false
        },
        {
          name: 'Holding Symbol',
          criteria:
            'Ticker symbol of the active security. For example "APPL". This value could be empty depending on the holding.',
          field_id: null,
          options: [],
          default_option: '',
          required: false,
          unique: true
        },
        {
          name: 'Holding Quantity',
          criteria: 'The total quantity of the active holding.',
          field_id: null,
          options: [],
          default_option: '',
          required: true,
          unique: false
        },
        {
          name: 'Holding CUSIP Number',
          criteria:
            'The nine-digit CUSIP number of the active holding. This value could be empty depending on the holding.',
          field_id: null,
          options: [],
          default_option: '',
          required: false,
          unique: true
        },
        {
          name: 'Holding Market Value',
          criteria:
            'The total market value of the active holding. This number can be negative or empty.',
          field_id: null,
          options: [],
          default_option: '',
          required: false,
          unique: true
        },
        {
          name: 'Holding Cost Basis',
          criteria:
            'The cost basis of the holding. This is not the price or adjusted cost basis and could be empty.',
          field_id: null,
          options: [],
          default_option: '',
          required: false,
          unique: false
        },
        {
          name: 'Holding Adjusted Cost Basis',
          criteria:
            'The adjusted cost basis of the holding. This is not the price or cost basis and could be empty.',
          field_id: null,
          options: [],
          default_option: '',
          required: false,
          unique: false
        }
      ]
    },
    {
      id: uuidv4(),
      question_type: 'multiple_value',
      field_ids: [],
      criteria: 'Transaction details where holding was bought or sold',
      run_email_mode: 'document_only',
      entities: [
        {
          name: 'Security Name',
          criteria:
            'Full name of the security that was involved in the transaction. For example, "Apple Inc".',
          field_id: null,
          options: [],
          default_option: '',
          required: true,
          unique: false
        },
        {
          name: 'Security Transaction Date',
          criteria: 'The date the transaction was settled.',
          field_id: null,
          options: [],
          default_option: '',
          required: true,
          unique: false
        },
        {
          name: 'Security Quantity',
          criteria:
            'The quantity of the security purchased in the transaction.',
          field_id: null,
          options: [],
          default_option: '',
          required: true,
          unique: false
        },
        {
          name: 'Transaction Type',
          criteria: 'Whether this transaction is a purchase or sale.',
          field_id: null,
          options: [
            { label: 'Purchase', value: 'Purchase' },
            { label: 'Sale', value: 'Sale' }
          ],
          default_option: '',
          required: true,
          unique: false
        }
      ]
    }
  ]
};

export const ENTITY_DATA: Record<GROUP_TYPE, any> = {
  cell_value: {
    labels: {
      name: 'Cell',
      details: 'Sheet'
    },
    helpers: {
      name: 'The exact column and row index of the cell',
      details:
        '(Optional) The exact sheet name to pull from. Defaults to the first sheet'
    },
    placeholders: {
      name: 'A3',
      details: 'Sheet1'
    },
    details: true,
    advanced: false
  },
  column_value: {
    labels: {
      name: 'Column',
      details: 'Details'
    },
    helpers: {
      name: 'A description of the column header. Will use AI to match',
      details: ''
    },
    placeholders: {
      name: 'Employee State',
      details: ''
    },
    details: false,
    advanced: false
  },
  one_value: {
    labels: {
      name: 'Entity'
    },
    helpers: {
      name: 'The name of the variable you want to extract',
      details: '(Optional) Additional entity details to tune the extraction'
    },
    placeholders: undefined,
    advanced: true
  },
  multiple_value: {
    labels: {
      name: 'Entity'
    },
    helpers: {
      name: 'The name of the variable you want to extract',
      details: '(Optional) Additional entity details to tune the extraction'
    },
    placeholders: undefined,
    advanced: true
  },
  yes_no: {
    labels: {
      name: 'Entity'
    },
    helpers: {
      name: 'The question you want to ask about a document attribute'
    },
    placeholders: {
      name: 'Is Account Investment',
      details: 'Is this document an investment account?'
    },
    advanced: false
  },
  page_number: {
    labels: {
      name: 'Name'
    },
    helpers: {
      name: 'The name of the page number query',
      details: 'Details that the page must match to return the page number'
    },
    placeholders: {
      name: 'Active Holding Pages',
      details: 'Pages that include active holdings'
    },
    advanced: false
  }
};

export type GROUP_TYPE =
  | 'cell_value'
  | 'column_value'
  | 'one_value'
  | 'multiple_value'
  | 'yes_no'
  | 'page_number';
