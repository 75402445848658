function Fathom({ width = 80, height = 80 }) {
  return (
    <img
      alt='Fathom logo'
      src={require('./Fathom.png')}
      style={{ width, height }}
    />
  );
}

export default Fathom;
