import '../../style/dialog-form.css';

import { Fragment, useState } from 'react';
import IntegrationsSidebar from './IntegrationsSidebar';

import { ALL_INTEGRATIONS_MAP, INTEGRATIONS } from './types';
import { DropdownField, InlineTooltip, TextField } from '../Core';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { FieldSelectorWithModal } from '../Modals';
import { PlusIcon, TrashIcon } from '../Icons';
import useIntegrations from './useIntegrations';
import useReceiveOauthMessage from './shared/useReceiveOauthMessage';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../hooks';
import { Button } from '../Core/Button/button';

const oauthRedirect = `https://account.box.com/api/oauth2/authorize?response_type=code&client_id=h4ikc28vtkwcud3jelw4zl8hf4kgu07d&redirect_uri=${location.origin}/oauth-redirect/`;

type FileConnection = {
  field_id: string;
  field_type: '' | 'servar' | 'hidden' | 'document';
  folder_id: string;
  name_field_id: string;
  name_field_type: '' | 'servar' | 'hidden';
};

const DEFAULT_FILE_CONNECTIONS: FileConnection[] = [
  {
    field_id: '',
    field_type: '',
    folder_id: '',
    name_field_id: '',
    name_field_type: ''
  }
];

function BoxSettingsSidebar() {
  const { formId } = useParams<{ formId: string }>();
  const documents = useAppSelector((state) => state.documents.documents);

  // Global/cached integration settings
  const integration = useIntegrations({
    type: INTEGRATIONS.BOX,
    panelId: formId,
    includeInactive: true
  });

  // Local/draft integration settings
  const [error, setError] = useState('');
  const [isPartial, setIsPartial] = useState(false);
  const meta = integration?.data.secret_metadata ?? {};
  const [fileConnections, setFileConnections] = useState<FileConnection[]>(
    meta.file_connections?.length
      ? meta.file_connections
      : DEFAULT_FILE_CONNECTIONS
  );
  const oauthToken: string | undefined = meta.oauth_token;

  function onSubmitCustom(newIsActive: boolean) {
    setError('');
    // Filter out completely empty custom property rows
    const filtered = fileConnections.filter(
      (fc) => fc.folder_id || fc.field_id
    );

    if (newIsActive) {
      let partial = false;
      filtered.forEach(({ folder_id, field_id }) => {
        if (!folder_id || !field_id) partial = true;
      });
      setIsPartial(partial);
      if (partial) {
        setError(
          'Folder connections must have both the field and folder ID specified'
        );
        return;
      }
    }

    if (!oauthToken) {
      setError('You must authorize your Box account');
    } else if (filtered.length === 0) {
      setError('You must specify at least one Box file connection');
    } else {
      return {
        isUpdate: integration?.data,
        apiKey: '',
        secretMetadata: {
          file_connections: filtered
        }
      };
    }
  }

  const authorizeBox = useReceiveOauthMessage(
    INTEGRATIONS.BOX,
    oauthRedirect,
    !!oauthToken
  );

  return (
    <IntegrationsSidebar
      integrationInfo={ALL_INTEGRATIONS_MAP[INTEGRATIONS.BOX]}
      authorizeData={{
        authorize: authorizeBox,
        oauthToken
      }}
      isPartial={isPartial}
      onSubmitCustom={onSubmitCustom}
      customError={error}
    >
      <form>
        {fileConnections.map((connection, index) => {
          const isDocument = connection.field_type === 'document';
          return (
            <div
              key={index}
              className={classNames(
                styles.productContainer,
                styles.boxContainer
              )}
            >
              <BaseRow
                connection={connection}
                index={index}
                fileConnections={fileConnections}
                setFileConnections={setFileConnections}
                isDocument={isDocument}
                isPartial={isPartial}
              />
              <ValueRow
                connection={connection}
                index={index}
                fileConnections={fileConnections}
                setFileConnections={setFileConnections}
                documents={documents}
                isPartial={isPartial}
                isDocument={isDocument}
              />
            </div>
          );
        })}
        <Button
          type='button'
          variant='outline'
          size='md'
          className='mt-[10px]'
          onClick={() => {
            const newFields = [...fileConnections];
            newFields.push(DEFAULT_FILE_CONNECTIONS[0]);
            setFileConnections(newFields);
          }}
        >
          <PlusIcon width={16} color='currentColor' />
          Add
        </Button>
      </form>
    </IntegrationsSidebar>
  );
}

export default BoxSettingsSidebar;

function BaseRow({
  connection,
  index,
  fileConnections,
  setFileConnections,
  isDocument,
  isPartial
}: {
  connection: FileConnection;
  index: number;
  fileConnections: FileConnection[];
  setFileConnections: React.Dispatch<FileConnection[]>;
  isDocument: boolean;
  isPartial: boolean;
}) {
  return (
    <Fragment>
      <div className={styles.fieldHeaderText}>
        Box Folder
        <InlineTooltip text='The folder ID of the Box folder to upload files to' />
      </div>
      <div className='flex gap-2 justify-between items-center'>
        <div className={styles.fieldHeaderText}>File Type</div>
        <TrashIcon
          height={16}
          width={16}
          className='tr-icon cursor-pointer'
          onClick={() => {
            const newConnections = [...fileConnections];
            newConnections.splice(index, 1);
            setFileConnections(newConnections);
          }}
        />
      </div>
      <TextField
        placeholder='Box Folder ID'
        className='dialog-form-input'
        value={connection.folder_id}
        onComplete={(newFolderId: string) => {
          const newConnections = [...fileConnections];
          newConnections[index] = {
            ...connection,
            folder_id: newFolderId
          };
          setFileConnections(newConnections);
        }}
        error={isPartial && !connection.folder_id}
      />
      <div className='flex gap-2 mb-[5px]'>
        <DropdownField
          selected={isDocument ? 'document' : 'field'}
          placeholder='Select type'
          options={[
            {
              value: 'field',
              display: 'Uploaded File'
            },
            {
              value: 'document',
              display: 'Generated Document'
            }
          ]}
          onChange={(event) => {
            const data = event.target.value;
            const newConnections = [...fileConnections];
            newConnections[index] = {
              field_id: '',
              field_type: data === 'document' ? 'document' : '',
              folder_id: newConnections[index].folder_id ?? '',
              name_field_id: '',
              name_field_type: ''
            };
            setFileConnections(newConnections);
          }}
        />
      </div>
    </Fragment>
  );
}

function ValueRow({
  connection,
  documents,
  fileConnections,
  index,
  setFileConnections,
  isDocument,
  isPartial
}: {
  connection: FileConnection;
  documents: Record<string, any>;
  index: number;
  fileConnections: FileConnection[];
  setFileConnections: React.Dispatch<FileConnection[]>;
  isDocument: boolean;
  isPartial: boolean;
}) {
  return (
    <Fragment>
      {isDocument ? (
        <div className={styles.fieldHeaderText}>
          Document
          <InlineTooltip text='When this document is generated it will be uploaded to Box.' />
        </div>
      ) : (
        <div className={styles.fieldHeaderText}>
          File Field
          <InlineTooltip text='The field receiving files to upload to the Box folder' />
        </div>
      )}
      <div className={styles.fieldHeaderText}>
        Name Field
        <InlineTooltip text='(Optional) A field containing a name to rename the uploaded Box files to' />
      </div>
      {isDocument ? (
        // Document Picker
        <DropdownField
          selected={connection.field_id}
          placeholder='Select document'
          options={Object.values(documents).map((document) => ({
            value: document.id,
            display: document.key
          }))}
          onChange={(event) => {
            const data = event.target.value;
            const newConnections = [...fileConnections];
            newConnections[index] = {
              ...connection,
              field_id: data,
              field_type: 'document'
            };
            setFileConnections(newConnections);
          }}
          error={isPartial && !connection.field_id}
        />
      ) : (
        // File Upload Picker
        <FieldSelectorWithModal
          selectId={connection.field_id}
          selectType={connection.field_type}
          placeholder='Select'
          onSelect={(data) => {
            const newConnections = [...fileConnections];
            newConnections[index] = {
              ...connection,
              field_id: data.selectId,
              field_type: data.selectType
            };
            setFileConnections(newConnections);
          }}
          error={isPartial && !connection.field_id}
          className={styles.fieldSelector}
          includeServarTypes={['file_upload']}
        />
      )}
      {/* Name Field  */}
      <FieldSelectorWithModal
        selectId={connection.name_field_id}
        selectType={connection.name_field_type}
        placeholder='Select'
        onSelect={(data) => {
          const newConnections = [...fileConnections];
          newConnections[index] = {
            ...connection,
            name_field_id: data.selectId,
            name_field_type: data.selectType
          };
          setFileConnections(newConnections);
        }}
        className={styles.fieldSelector}
        excludeServarTypes={['file_upload', 'signature']}
      />
    </Fragment>
  );
}
