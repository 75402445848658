function Relativity6({ width = 140, height = 50 }) {
  return (
    <img
      alt='Relativity6 logo'
      src={require('./Relativity6.png')}
      style={{ width, height }}
    />
  );
}

export default Relativity6;
