import { ENTITY_DATA, getDefaultEntity, GROUP_TYPE } from '../utils';
import Entity from './Entity';
import classNames from 'classnames';
import styles from './styles.module.scss';
import GroupType from './GroupType';
import { forwardRef } from 'react';

const TYPE_OPTIONS = [
  { value: 'cell_value', display: 'Cell' },
  {
    value: 'column_value',
    display: 'Column'
  }
];

export const CSVQuestionGroup = forwardRef(
  (
    { group, onUpdate, index, fieldProperties, hiddenFieldProperties }: any,
    ref: any
  ) => {
    return (
      <div
        ref={ref}
        className={classNames(styles.keyValuePairs, styles.moreGap)}
      >
        <GroupType group={group} onUpdate={onUpdate} options={TYPE_OPTIONS} />
        <div className={styles.keyValuePair}>
          <div className={styles.entitiesContainer}>
            {group.entities?.map((entity: any, entityIndex: number) => (
              <Entity
                key={`group-${index}-entity-${entityIndex + 1}`}
                type={group.question_type}
                entity={entity}
                fieldProperties={fieldProperties}
                hiddenFieldProperties={hiddenFieldProperties}
                onUpdate={(key: string | object, value?: any) => {
                  const updatedEntities = [...group.entities];
                  updatedEntities[entityIndex] =
                    typeof key !== 'string'
                      ? {
                          ...entity,
                          ...key
                        }
                      : {
                          ...entity,
                          [key]: value
                        };
                  onUpdate('entities', updatedEntities);
                }}
                onRemove={() => {
                  const updatedEntities = [...group.entities];
                  updatedEntities.splice(entityIndex, 1);
                  if (updatedEntities.length === 0) {
                    updatedEntities.push(getDefaultEntity());
                  }
                  onUpdate('entities', updatedEntities, entityIndex, 'remove');
                }}
                {...ENTITY_DATA[group.question_type as GROUP_TYPE]}
              />
            ))}
          </div>
        </div>
      </div>
    );
  }
);

CSVQuestionGroup.displayName = 'CSVQuestionGroup';
