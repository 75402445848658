import { useState } from 'react';
import { useAppSelector } from '.';

export interface PermissionConfigProps {
  role?: 'admin' | 'editor' | 'viewer';
  permissionEditFormResults?: boolean | null;
  permissionInviteCollaborators?: boolean | null;
  permissionEditCollaboratorTemplate?: boolean | null;
  permissionEditFormDesign?: boolean | null;
  permissionEditLogic?: boolean | null;
  permissionEditTheme?: boolean | null;
  permissionPublishForms?: boolean | null;
  permissionEditLinkedProperties?: boolean | null;
  permissionDeleteForms?: boolean | null;
  permissionFilterResults?: {
    field_type: string;
    field_id: string;
    field_value: string;
  } | null;
  showUserGroups?: boolean | null;
  groupIds?: string[];
}

export function usePermissionsConfig({
  role = 'admin',
  permissionEditFormResults = true,
  permissionInviteCollaborators = true,
  permissionEditCollaboratorTemplate = true,
  permissionEditFormDesign = true,
  permissionEditLogic = true,
  permissionEditTheme = true,
  permissionPublishForms = true,
  permissionDeleteForms = true,
  permissionEditLinkedProperties = true,
  permissionFilterResults = null,
  showUserGroups = false,
  groupIds = []
}: PermissionConfigProps) {
  const allGroups = useAppSelector(
    (state) => state.accounts.organization?.all_user_groups ?? {}
  );
  const org = useAppSelector((state) => state.accounts.organization);
  const isEnterprise = org?.tier >= 4;

  const [curGroups, setCurGroups] = useState<string[]>(groupIds);
  const [curRole, setCurRole] = useState(role);

  const [editFormResults, setEditFormResults] = useState(
    permissionEditFormResults
  );
  const [inviteCollaborators, setInviteCollaborators] = useState(
    permissionInviteCollaborators
  );
  const [editFormDesign, setEditFormDesign] = useState(
    permissionEditFormDesign
  );
  const [editLogic, setEditLogic] = useState(permissionEditLogic);
  const [editTheme, setEditTheme] = useState(permissionEditTheme);
  const [deleteForms, setDeleteForms] = useState(permissionDeleteForms);
  const [publishForms, setPublishForms] = useState(permissionPublishForms);
  const [editLinkedProperties, setEditLinkedProperties] = useState(
    permissionEditLinkedProperties
  );
  const [editCollaboratorTemplate, setEditCollaboratorTemplate] = useState(
    permissionEditCollaboratorTemplate
  );
  const [filterPermission, setFilterPermission] = useState(
    permissionFilterResults
  );

  function getSubmitData() {
    const curFilterPermission =
      curRole === 'viewer' && filterPermission?.field_id
        ? filterPermission
        : null;
    const submitData: Record<string, any> = {
      role: curRole || role,
      permission_edit_form_results: editFormResults,
      permission_invite_collaborators: inviteCollaborators,
      permission_edit_form_design: editFormDesign,
      permission_edit_logic: editLogic,
      permission_edit_collaborator_template: editCollaboratorTemplate,
      permission_filter_results: curFilterPermission,
      permission_edit_theme: editTheme,
      permission_delete_forms: deleteForms,
      permission_publish_forms: publishForms,
      permission_edit_linked_properties: editLinkedProperties
    };
    if (showUserGroups) submitData.user_groups = curGroups;
    return submitData;
  }

  function getSubmitFunction(onSubmit: (data: Record<string, any>) => void) {
    return () => onSubmit(getSubmitData());
  }

  return {
    allGroups,
    isEnterprise,
    curGroups,
    setCurGroups,
    curRole,
    setCurRole,
    permissions: {
      editFormResults,
      setEditFormResults,
      inviteCollaborators,
      setInviteCollaborators,
      editFormDesign,
      setEditFormDesign,
      editLogic,
      setEditLogic,
      editTheme,
      setEditTheme,
      publishForms,
      setPublishForms,
      editLinkedProperties,
      setEditLinkedProperties,
      editCollaboratorTemplate,
      setEditCollaboratorTemplate,
      filterPermission,
      setFilterPermission,
      deleteForms,
      setDeleteForms
    },
    getSubmitFunction,
    submissionData: getSubmitData()
  };
}
