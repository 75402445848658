function PinpointPredictive({ width = 70, height = 70 }) {
  return (
    <img
      alt='Pinpoint Predictive logo'
      src={require('./PinpointPredictive.jpeg')}
      style={{ width, height }}
    />
  );
}

export default PinpointPredictive;
