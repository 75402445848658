import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  Viewport,
  ViewportType
} from '../components/RenderingEngine/GridInGrid/engine/viewports';
import { parseAndThrowError } from './utils';

const getOrganization = createAsyncThunk(
  'accounts/getOrganization',
  async ({ token, ...data }: { token: string }) => {
    const response = await FeatheryAPI.getOrganization(token, data);
    const payload = await response.json();
    if (response.status === 200) {
      return payload;
    } else {
      parseAndThrowError(payload, 'An unknown error occurred. Try again later');
    }
  }
);

const getDefaultSettings = createAsyncThunk(
  'accounts/getDefaultSettings',
  async ({ token }: { token: string }) => {
    if (Object.keys(FeatheryConfig).length > 0) return;

    const response = await FeatheryAPI.getDefaultSettings(token);
    const payload = await response.json();
    if (response.status === 200) {
      Object.assign(FeatheryConfig, payload);
    } else {
      parseAndThrowError(payload, 'An unknown error occurred. Try again later');
    }
  }
);

const editOrganization = createAsyncThunk(
  'accounts/editOrganization',
  async ({ token, ...data }: { token: string }) => {
    const response = await FeatheryAPI.editOrganization(token, data);
    const payload = await response.json();
    if (response.status === 200) {
      return payload;
    } else {
      parseAndThrowError(payload, 'Could not edit account');
    }
  }
);

const editFavicon = createAsyncThunk(
  'accounts/editFavicon',
  async ({ token, favicon }: { token: string; favicon: any }) => {
    const response = await FeatheryAPI.editFavicon(token, favicon);
    const payload = await response.json();
    if (response.status === 200) {
      return payload;
    } else {
      parseAndThrowError(payload, 'Could not edit favicon');
    }
  }
);

const migrateAccount = createAsyncThunk(
  'accounts/migrateAccount',
  async ({ token, destination }: { token: string; destination: string }) => {
    const response = await FeatheryAPI.migrateAccount(token, destination);
    const payload = await response.json();
    if (response.status === 200) {
      return payload;
    } else {
      parseAndThrowError(payload, 'Could not edit account');
    }
  }
);

const editAccount = createAsyncThunk(
  'accounts/editAccount',
  async ({ token, ...data }: { token: string }) => {
    const response = await FeatheryAPI.editAccount(token, data);
    const payload = await response.json();
    if (response.status === 200) {
      return payload;
    } else {
      parseAndThrowError(payload, 'Could not edit account');
    }
  }
);

/* eslint-disable camelcase */
const updateAccountViewport = createAsyncThunk(
  'accounts/updateViewport',
  ({
    token,
    viewport_toggle
  }: {
    token: string;
    viewport_toggle: ViewportType;
  }) => {
    // We want to optimistically update the viewport, so don't await this
    FeatheryAPI.editAccount(token, { viewport_toggle });
    return { viewport_toggle };
  }
);
/* eslint-enable camelcase */

const getBill = createAsyncThunk(
  'accounts/getBill',
  async ({ token }: { token: string }) => {
    const response = await FeatheryAPI.getBill(token);
    if (response.status === 200) {
      return await response.json();
    } else {
      throw new Error('Could not retrieve bill');
    }
  }
);

const updateCard = createAsyncThunk(
  'accounts/updateCard',
  async ({ token, paymentMethod }: { token: string; paymentMethod: any }) => {
    const response = await FeatheryAPI.updateCard(token, paymentMethod);
    if (response.status === 200) {
      return await response.json();
    } else {
      throw new Error('Could not update payment card');
    }
  }
);

const inviteUsers = createAsyncThunk(
  'accounts/inviteUsers',
  async ({ token, invites }: { token: string; invites: any[] }) => {
    const response = await FeatheryAPI.inviteUsers(token, invites);
    const payload = await response.json();
    if (response.status === 201) {
      return payload;
    } else {
      parseAndThrowError(payload, 'Could not invite users');
    }
  }
);

const removeUser = createAsyncThunk(
  'accounts/removeUser',
  async ({ token, email }: { token: string; email: string }) => {
    const response = await FeatheryAPI.removeUser(token, email);
    if (response.status === 200) {
      return await response.json();
    } else {
      throw new Error('Could not remove user');
    }
  }
);

const getWorkspaces = createAsyncThunk(
  'accounts/getWorkspaces',
  async ({ token, ...data }: { token: string }) => {
    const response = await FeatheryAPI.getWorkspaces(token, data);
    const payload = await response.json();
    if (response.status === 200) {
      return payload;
    } else {
      parseAndThrowError(payload, 'Could not list workspaces.');
    }
  }
);

const getWorkspace = createAsyncThunk(
  'accounts/getWorkspace',
  async ({ token, workspaceId }: { token: string; workspaceId: string }) => {
    const response = await FeatheryAPI.getWorkspace(token, workspaceId);

    if (response.status === 200) {
      return await response.json();
    }

    throw new Error('Could not get workspace');
  }
);

const createWorkspace = createAsyncThunk(
  'accounts/createWorkspace',
  async ({ token, ...data }: { token: string }, thunkAPI) => {
    const response = await FeatheryAPI.createWorkspace(token, data);
    const payload = await response.json();
    if (response.status === 201) {
      // refetch workspaces
      thunkAPI.dispatch(getWorkspaces({ token }));
      return payload;
    } else {
      parseAndThrowError(payload, 'Could not create workspace.');
    }
  }
);

const editWorkspace = createAsyncThunk(
  'accounts/editWorkspace',
  async ({ token, ...data }: { token: string; workspaceId: string }) => {
    const response = await FeatheryAPI.editWorkspace(token, data);
    const payload = await response.json();
    if (response.status === 200) {
      return payload;
    } else {
      parseAndThrowError(payload, 'Could not edit workspace.');
    }
  }
);

const deleteWorkspace = createAsyncThunk(
  'accounts/deleteWorkspace',
  async (
    { token, workspaceId }: { token: string; workspaceId: string },
    thunkAPI
  ) => {
    const response = await FeatheryAPI.deleteWorkspace(token, workspaceId);
    if (response.status === 204) {
      // refetch workspaces
      thunkAPI.dispatch(getWorkspaces({ token }));
      return;
    } else {
      const payload = response.json();
      parseAndThrowError(payload, 'Could not delete workspace.');
    }
  }
);

const inviteToWorkspace = createAsyncThunk(
  'accounts/inviteToWorkspace',
  async ({
    token,
    workspaceId,
    ...data
  }: {
    token: string;
    workspaceId: string;
  }) => {
    const response = await FeatheryAPI.inviteToWorkspace(
      token,
      workspaceId,
      data
    );
    const payload = await response.json();
    if (response.status === 200) {
      return payload;
    } else {
      parseAndThrowError(payload, 'Could not edit workspace account.');
    }
  }
);

const createCustomField = createAsyncThunk(
  'accounts/createCustomField',
  async ({
    token,
    ...data
  }: {
    token: string;
    label: string;
    key: string;
    icon: string;
    code: string;
  }) => {
    const response = await FeatheryAPI.createCustomField(token, data);
    const payload = await response.json();
    if (response.status === 201) {
      return payload;
    } else {
      parseAndThrowError(payload, 'Could not create custom field.');
    }
  }
);
const updateCustomField = createAsyncThunk(
  'accounts/updateCustomField',
  async ({
    token,
    id,
    ...data
  }: {
    token: string;
    id: string;
    label: string;
    key: string;
    icon: string;
    code: string;
  }) => {
    const response = await FeatheryAPI.updateCustomField(token, id, data);
    const payload = await response.json();
    if (response.status === 200) {
      return payload;
    } else {
      parseAndThrowError(payload, 'Could not update custom field.');
    }
  }
);
const deleteCustomField = createAsyncThunk(
  'accounts/deleteCustomField',
  async ({ token, id }: { token: string; id: string; key: string }) => {
    const response = await FeatheryAPI.deleteCustomField(token, id);
    if (response.status === 204) return;
    else {
      const payload = response.json();
      parseAndThrowError(payload, 'Could not delete workspace.');
    }
  }
);

const updateConfig = createAsyncThunk(
  'accounts/updateConfig',
  async ({ token, data }: { token: string; data: any }) => {
    const response = await FeatheryAPI.updateConfig(token, data);
    if (response.status === 200) return;
    else {
      const payload = response.json();
      parseAndThrowError(payload, 'Could not update config.');
    }
  }
);

const DEFAULT_FOLDER_VIEWS: Record<string, boolean | string> = {};

const DEFAULT_ACCOUNT_VALUES = {
  id: '',
  viewport_toggle: Viewport.Desktop,
  element_select_view: 'block',
  grid_mode_toggle: true,
  onboarding_data: { finished: false },
  email: '',
  auth_id: '',
  receive_error_notifications: false,
  role: 'viewer',
  permission_edit_form_design: true,
  permission_edit_form_results: true,
  permission_edit_logic: true,
  permission_invite_collaborators: true,
  permission_edit_collaborator_template: true,
  permission_edit_theme: true,
  permission_edit_linked_properties: true,
  permission_filter_results: null,
  permission_delete_forms: true,
  permission_publish_forms: true,
  pending_inviters: [],
  pending_permissions: [],
  user_groups: [],
  task_list_filters: {},
  first_name: '',
  last_name: '',
  custom_attributes: {},
  folder_views: DEFAULT_FOLDER_VIEWS
};

const updateOrg = (state: any, newPayload: any) => {
  const feats = newPayload.enterprise_features;
  state.organization = {
    ...newPayload,
    hipaaIntegrationWhitelist: !feats.hipaa || feats.hipaa_whitelist
  };
};

const accountsSlice = createSlice({
  name: 'accounts',
  initialState: {
    organization: null as null | Record<string, any>,
    account: DEFAULT_ACCOUNT_VALUES,
    bill: {
      bill_date: '',
      amount: 0,
      subscription_start: ''
    },
    token: '',
    workspaces: {} as Record<string, any>,
    workspaceDetails: null as null | any
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getOrganization.fulfilled, (state, action) => {
      const { account, ...restOfOrg } = action.payload;
      state.account = account;
      updateOrg(state, restOfOrg);
    });
    builder.addCase(getOrganization.rejected, (state, action) => {
      state.organization = null;
      state.account = DEFAULT_ACCOUNT_VALUES;
    });
    builder.addCase(editOrganization.fulfilled, (state: any, action: any) => {
      const { account, ...restOfOrg } = action.payload;
      state.account = account;
      updateOrg(state, restOfOrg);
    });
    builder.addCase(editFavicon.fulfilled, (state: any, action: any) => {
      const { favicon } = action.payload;
      state.organization.favicon = favicon;
    });
    builder.addCase(editAccount.fulfilled, (state: any, action: any) => {
      const otherAccountEmail = action.meta.arg.account_email;
      if (otherAccountEmail) {
        const otherAccount = state.organization.team_accounts.find(
          ({ email }: any) => email === otherAccountEmail
        );
        if (otherAccount) {
          Object.assign(otherAccount, action.payload);
        }
      } else {
        state.account = action.payload;
      }
    });
    builder.addCase(editAccount.rejected, (state: any, action: any) => {
      // Modify user preferences in current session even if not saved to backend successfully
      state.account = { ...action.meta.arg, ...state.account };
    });
    builder.addCase(
      updateAccountViewport.fulfilled,
      (state: any, action: any) => {
        state.account.viewport_toggle = action.payload.viewport_toggle;
      }
    );
    builder.addCase(getBill.fulfilled, (state: any, action: any) => {
      state.bill = action.payload;
    });
    builder.addCase(updateCard.fulfilled, (state: any, action: any) => {
      state.organization = action.payload;
    });
    builder.addCase(inviteUsers.fulfilled, (state: any, action: any) => {
      state.organization.team_accounts = action.payload;
    });
    builder.addCase(removeUser.fulfilled, (state: any, action: any) => {
      state.organization.team_accounts = action.payload;
    });
    builder.addCase(getWorkspaces.fulfilled, (state: any, action: any) => {
      const { payload } = action;
      state.workspaces.data = payload.results;
      state.workspaces.count = payload.count;
      state.workspaces.prevUrl = payload.previous;
      state.workspaces.nextUrl = payload.next;
    });
    builder.addCase(getWorkspace.fulfilled, (state: any, action: any) => {
      state.workspaceDetails = action.payload;
    });
    builder.addCase(inviteToWorkspace.fulfilled, (state: any, action: any) => {
      state.workspaceDetails.accounts = action.payload.accounts;
    });
    builder.addCase(editWorkspace.fulfilled, (state: any, action: any) => {
      state.workspaceDetails = action.payload;
    });
    builder.addCase(createCustomField.fulfilled, (state: any, action: any) => {
      // Update Redux state by adding the new custom field
      if (!state.organization.custom_fields) {
        state.organization.custom_fields = [];
      }
      state.organization.custom_fields.push(action.payload);

      // Register in FeatheryConfig
      const custom = JSON.parse(
        JSON.stringify(FeatheryConfig.default_element_properties.custom)
      );
      custom.servar.metadata.code = action.payload.code;
      custom.servar.metadata.custom_field_key = action.payload.key;
      FeatheryConfig.default_element_properties[
        `custom-${action.payload.key}`
      ] = custom;
    });
    builder.addCase(updateCustomField.fulfilled, (state: any, action: any) => {
      const index = state.organization.custom_fields.findIndex(
        (field: any) => field.id === action.payload.id
      );

      if (index !== -1) {
        const oldField = state.organization.custom_fields[index];
        state.organization.custom_fields[index] = action.payload;

        if (oldField.key !== action.payload.key) {
          delete FeatheryConfig.default_element_properties[
            `custom-${oldField.key}`
          ];
        }

        const custom = JSON.parse(
          JSON.stringify(FeatheryConfig.default_element_properties.custom)
        );
        custom.servar.metadata.code = action.payload.code;
        custom.servar.metadata.custom_field_key = action.payload.key;
        FeatheryConfig.default_element_properties[
          `custom-${action.payload.key}`
        ] = custom;
      }
    });
    builder.addCase(deleteCustomField.fulfilled, (state: any, action: any) => {
      const customfield_id = action.meta.arg.id;
      const field = state.organization.custom_fields.find(
        (field: any) => field.id === customfield_id
      );
      state.organization.custom_fields =
        state.organization.custom_fields.filter(
          (field: any) => field.id !== customfield_id
        );
      delete FeatheryConfig.default_element_properties[`custom-${field.key}`];
    });
    builder.addCase(getDefaultSettings.fulfilled, (state: any) => {
      const customFields = state.organization.custom_fields;

      if (customFields) {
        customFields.forEach((field: any) => {
          const custom = JSON.parse(
            JSON.stringify(FeatheryConfig.default_element_properties.custom)
          );
          custom.servar.metadata.code = field.code;
          custom.servar.metadata.custom_field_key = field.key;
          FeatheryConfig.default_element_properties[`custom-${field.key}`] =
            custom;
        });
      }
    });
  }
});

export default accountsSlice.reducer;
export {
  getDefaultSettings,
  getOrganization,
  editOrganization,
  editAccount,
  editFavicon,
  migrateAccount,
  updateAccountViewport,
  getBill,
  updateCard,
  inviteUsers,
  removeUser,
  getWorkspaces,
  getWorkspace,
  createWorkspace,
  editWorkspace,
  deleteWorkspace,
  inviteToWorkspace,
  createCustomField,
  deleteCustomField,
  updateCustomField,
  updateConfig
};
